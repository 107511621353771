import React, { useState } from "react";
import { Collapse, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function GrandchildMenuItem({ item, selectedPath, openDrawer, openAction }, ...props) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [grandchildToggle, setGrandchildToggle] = useState(false);

    const handleGrandchildToggle = () => {
        if (!openDrawer) {
            openAction();
            setGrandchildToggle(true);
        } else {
            setGrandchildToggle(!grandchildToggle);
        }
    };

    return (
        <React.Fragment>
            <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemButton sx={{ py: 0 }} onClick={handleGrandchildToggle}>
                    <ListItemText
                        sx={{
                            pl: 5,
                        }}
                        primary={
                            <Typography
                                style={{
                                    fontFamily: "Inter",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                }}
                            >
                                {item.label}
                            </Typography>
                        }
                    />
                    {grandchildToggle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
            </ListItem>
            <Collapse sx={{ py: 0 }} in={openDrawer && grandchildToggle} timeout="auto" unmountOnExit>
                <List sx={{ py: 0 }}>
                    {item.children.map((grandchild, grandIndex) => (
                        <ListItem key={grandIndex} sx={{ py: 0.5, px: 0 }}>
                            <ListItemButton
                                key={grandIndex}
                                onClick={() => {
                                    if (grandchild.path.includes("//")) {
                                        const oId = JSON.parse(
                                            localStorage.getItem("userOrganizationInfo")
                                        )?.organizationId;
                                        grandchild.path.replace("//", `/${oId}/`);
                                    }
                                    navigate(grandchild.path);
                                }}
                                selected={selectedPath === `${grandchild.path}`}
                                sx={{
                                    py: 0,
                                    pl: 10,
                                    "&.Mui-selected": {
                                        backgroundColor: theme.palette.primary.dark,
                                    },
                                    ":hover": {
                                        backgroundColor: theme.palette.primary.dark,
                                    },
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography
                                            style={{
                                                fontFamily: "Inter",
                                                fontWeight: "400",
                                                fontSize: "14px",
                                            }}
                                        >
                                            {grandchild.label}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Collapse>
        </React.Fragment>
    );
}
