import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { chaoticOrbit } from "ldrs";
import { useState } from "react";

const LoadingSpinner = () => {
    chaoticOrbit.register();
    const [color, setColor] = useState("");

    useEffect(() => {
        setColor("#" + JSON.parse(localStorage.getItem("userOrganizationInfo")).theme.headerColour);

        return () => {};
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh", // Full viewport height
            }}
        >
            <l-chaotic-orbit size="70" speed="1.5" color={color}></l-chaotic-orbit>
        </Box>
    );
};

export default LoadingSpinner;
