import React from "react";

export default function TireQuoteWheels() {
    return (
        <div>
            <h1>Wheel Data Page</h1>
            <p>
                This page will contain all the wheel records that are visible to the site manager. This data will be
                different from the wheel list on the User view since here the wheels will contain additional information
                not visible to standard users and allow for actions such as price modifications and reports on a
                wheel-by-wheel basis.
            </p>
        </div>
    );
}
