import React from "react";

export default function TireQuoteTires() {
    return (
        <div>
            <h1>Tire Data Page</h1>
            <p>
                This page will contain all the tires that are visible to the site manager. This data will be different
                from the tire list on the User view since here the tires will contain additional information not visible
                to standard users and allow for actions such as price modifications and reports on a tire-by-tire basis.
            </p>
        </div>
    );
}
