import { Navigate } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { useEffect, useState } from "react";
import LoadingSpinner from "../components/Spinner";

const ProtectedRouteWrapper = ({ Component }) => {
    const { checkToken } = useAuth();
    const [isAuth, setIsAuth] = useState(null);

    useEffect(() => {
        const validate = async () => {
            const isValid = await checkToken();
            setIsAuth(isValid);
        };

        validate();
    }, [checkToken]);

    if (isAuth === null) {
        // Show a loading spinner while checking token validity
        return <LoadingSpinner />;
    }

    return isAuth ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRouteWrapper;
