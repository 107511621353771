import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomTabPanel from "../../../components/CustomTabPanel";
import AddIcon from "@mui/icons-material/Add";
import { DataTable } from "../../../components/DataTable/DataTable";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { Trash as TrashIcon } from "@phosphor-icons/react/dist/ssr/Trash";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import AddRuleDialog from "../../../components/AddRuleDialog";

const rows = [
    {
        appliedTo: "All Tires",
        startDate: "01/10/2023",
        endDate: "01/04/2024",
        seasonScope: "Winter 2023",
        rule: "(COST + 20%) + $80",
        ruleType: "FORMULA",
        ruleNotes: "",
    },
    {
        appliedTo: "Winter Tires",
        startDate: "01/10/2023",
        endDate: "01/04/2024",
        seasonScope: "ALL Year",
        rule: "$45",
        ruleType: "FLAT RATE",
        ruleNotes: "",
    },
];

const wheelRows = [
    {
        appliedTo: "Wheels",
        startDate: "01/10/2023",
        endDate: "01/04/2024",
        seasonScope: "Winter 2023",
        rule: "(COST + 20%) + $80",
        ruleType: "FORMULA",
        ruleNotes: "",
    },
];

const packageRows = [
    {
        appliedTo: "Winter Package",
        startDate: "01/10/2023",
        endDate: "01/04/2024",
        seasonScope: "Winter 2023",
        rule: "(COST + 20%) + $80",
        ruleType: "FORMULA",
        ruleNotes: "",
    },
    {
        appliedTo: "All Year Package",
        startDate: "01/10/2023",
        endDate: "01/04/2024",
        seasonScope: "ALL Year",
        rule: "$45",
        ruleType: "FLAT RATE",
        ruleNotes: "",
    },
];


const accessoryRows = [
    {
        appliedTo: "All accessories",
        startDate: "01/10/2023",
        endDate: "01/04/2024",
        seasonScope: "Winter 2023",
        rule: "(COST + 20%) + $80",
        ruleType: "FORMULA",
        ruleNotes: "",
    },
];

const columns = [
    {
        formatter: (row) => {
            return (
                <Stack>
                    <Box>
                        <Typography variant="caption" display={"inline"}>
                            Applied to{" "}
                        </Typography>
                        <Typography variant="caption" display={"inline"}>
                            <strong>{row.appliedTo}</strong>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="caption" display={"inline"}>
                            Active from{" "}
                        </Typography>
                        <Typography variant="body2" display={"inline"}>
                            <strong>{row.startDate}</strong> - <strong>{row.endDate}</strong>
                        </Typography>
                    </Box>
                </Stack>
            );
        },
        name: "Role Scope",
        width: "300px",
    },
    { field: "seasonScope", name: "Season Scoop", width: "150px" },
    {
        formatter: (row) => {
            return (
                <Stack>
                    <Typography variant="caption">{row.ruleType}:</Typography>
                    <Typography variant="body2">{row.rule}</Typography>
                </Stack>
            );
        },
        name: "Rule Type",
        width: "200px",
    },
    { field: "ruleNotes", name: "Rule Notes", width: "200px" },
    {
        formatter: (row) => {
            return (
                <Box>
                    <Tooltip title="Edit Rule" placement="top">
                        <IconButton
                        // component={RouterLink}
                        // to={row.pageUrl}
                        >
                            <PencilSimpleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Rule" placement="top">
                        <IconButton>
                            <TrashIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        },
        name: "Action",
        width: "100px",
        align: "center",
    },
];

const sortingOptions = [
    {
        field: "date",
        label: "Date",
        order: ["asc", "desc"],
    },
];

const filteringOptions = [
    {
        field: "season",
        label: "Season",
        labelDescription: "Filter by Season",
        fieldType: "dropdown",
        values: ["All year", "Winter", "Spring"],
    },
    {
        field: "status",
        label: "Status",
        labelDescription: "Filter by Status",
        fieldType: "tab",
        values: ["Active", "Paused", "Inactive"],
    },
];

export default function Pricing() {
    const [tabValue, setTabValue] = useState(0);
    const [addRuleOpen, setAddRuleOpen] = useState(false);
    const [addRuleDialog, setAddRuleDialog] = useState({
        open: false,
        type: "",
    });

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleAddRule = (type) => {
        setAddRuleDialog({
            open: true,
            type: "Tire",
        });
    };

    return (
        <div>
            <Stack spacing={1}>
                <Card>
                    <CardHeader title={<Typography variant="h6">Pricing Rules</Typography>} />
                    <CardContent sx={{ pt: 0 }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                <Tab label="Tires" />
                                <Tab label="Wheels" />
                                <Tab label="Packages" />
                                <Tab label="Accessories" />
                                <Tab label="TPMS" />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={tabValue} index={0}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item md={6}>
                                    <Card sx={{ borderRadius: 1 }} variant="outlined">
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Box>
                                                <Typography variant={"subtitle2"} color="textSecondary">
                                                    Global Tire Rule:
                                                </Typography>
                                                <Typography>Price + 18% Markup + $120 Flat Rate</Typography>
                                            </Box>
                                            <Box>
                                                <Button variant="outlined">Edit</Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ mr: 1 }}
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleAddRule("Tire")}
                                    >
                                        Add Rule
                                    </Button>
                                </Grid>
                            </Grid>
                            {/* <CardContent> */}
                                <DataTableFilters sorts={sortingOptions} filters={filteringOptions} />
                                <DataTable dense={true} columns={columns} rows={rows} />
                                <DataTablePagination />
                            {/* </CardContent> */}
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={1}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item md={6}>
                                    <Card sx={{ borderRadius: 1 }} variant="outlined">
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Box>
                                                <Typography variant={"subtitle2"} color="textSecondary">
                                                    Global Wheels Rule:
                                                </Typography>
                                                <Typography>Cost + 17% Markup + $73 Flat Rate</Typography>
                                            </Box>
                                            <Box>
                                                <Button variant="outlined">Edit</Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ mr: 1 }}
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleAddRule("Wheel")}
                                    >
                                        Add Rule
                                    </Button>
                                </Grid>
                            </Grid>
                            <DataTableFilters sorts={sortingOptions} filters={filteringOptions} />
                                <DataTable dense={true} columns={columns} rows={wheelRows} />
                                <DataTablePagination />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={2}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item md={6}>
                                    <Card sx={{ borderRadius: 1 }} variant="outlined">
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Box>
                                                <Typography variant={"subtitle2"} color="textSecondary">
                                                    Global Packages Rule:
                                                </Typography>
                                                <Typography>Price + 19% Markup + $35 Flat Rate</Typography>
                                            </Box>
                                            <Box>
                                                <Button variant="outlined">Edit</Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ mr: 1 }}
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleAddRule("Package")}
                                    >
                                        Add Rule
                                    </Button>
                                </Grid>
                            </Grid>
                            <DataTableFilters sorts={sortingOptions} filters={filteringOptions} />
                                <DataTable dense={true} columns={columns} rows={packageRows} />
                                <DataTablePagination />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={3}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item md={6}>
                                    <Card sx={{ borderRadius: 1 }} variant="outlined">
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Box>
                                                <Typography variant={"subtitle2"} color="textSecondary">
                                                    Global Accessories Rule:
                                                </Typography>
                                                <Typography>Cost + 26% Markup + $37 Flat Rate</Typography>
                                            </Box>
                                            <Box>
                                                <Button variant="outlined">Edit</Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ mr: 1 }}
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleAddRule("Accessories")}
                                    >
                                        Add Rule
                                    </Button>
                                </Grid>
                            </Grid>
                            <DataTableFilters sorts={sortingOptions} filters={filteringOptions} />
                                <DataTable dense={true} columns={columns} rows={accessoryRows} />
                                <DataTablePagination />
                        </CustomTabPanel>
                        <CustomTabPanel value={tabValue} index={4}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item md={6}>
                                    <Card sx={{ borderRadius: 1 }} variant="outlined">
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Box>
                                                <Typography variant={"subtitle2"} color="textSecondary">
                                                    Global TPMS Rule:
                                                </Typography>
                                                <Typography>Price + 28% Markup + $18.50 Flat Rate</Typography>
                                            </Box>
                                            <Box>
                                                <Button variant="outlined">Edit</Button>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item>
                                    <Button
                                        sx={{ mr: 1 }}
                                        variant="contained"
                                        startIcon={<AddIcon />}
                                        onClick={() => handleAddRule("TPMS")}
                                    >
                                        Add Rule
                                    </Button>
                                </Grid>
                            </Grid>
                        </CustomTabPanel>
                    </CardContent>
                </Card>
            </Stack>
            <AddRuleDialog
                open={addRuleDialog.open}
                type={addRuleDialog.type}
                onClose={() => setAddRuleDialog({ ...addRuleDialog, open: false })}
            />
        </div>
    );
}
