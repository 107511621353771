import { useNavigate } from "react-router-dom";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../getSubdomain";

const useAuth = () => {
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            const response = await fetch(settings.api().refreshToken, {
                method: "POST",
                credentials: "include", // Send cookies
            });
            console.log("refreshToken")

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem("authToken", data.token);
                return data.token;
            } else {
                throw new Error("Failed to refresh token");
            }
        } catch (error) {
            console.error("Error refreshing token", error);
            localStorage.removeItem("user");
            navigate("/login");
            return null;
        }
    };

    const checkToken = async () => {
        const token = localStorage.getItem("authToken");

        if (!token) {
            navigate("/login");
            return false;
        }

        try {
            const subdomain = getSubdomain();
            const domain = getDomain();
            const response = await fetch(settings.api().checkToken, {
                headers: {
                    "X-Domain": domain,
                    "X-Tenant": subdomain,
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                console.log("checkToken");

                return true;
            } else if (response.status === 401) {
                // Token expired or invalid, attempt to refresh
                const newToken = await refreshToken();
                return !!newToken;
            } else {
                throw new Error("Token validation failed");
            }
        } catch (error) {
            console.error("Error checking token:", error);
            localStorage.removeItem("user");
            navigate("/login");
            return false;
        }
    };

    return { checkToken, refreshToken };
};

export default useAuth;
