import React from "react";

export default function OrgAdminDashboard() {
    return (
        <div>
            <h1>Portal Management Dashboard</h1>
            <p>
                The Portal View allows for the management of an entire organization. A portal administrator can view all
                sites in an organization while regional managers can view sites in their respected regions. Any site the
                admin has access to can be opened in the Site admin view for editing without the need to log into the
                site directly.
            </p>
            <p>
                The Portal Dashboard will contain widgets such as graphs and reports that quickly guide a portal manager
                or admin to various organization components. The dashboard provides an overall snapshot of the entire
                organization.
            </p>
        </div>
    );
}
