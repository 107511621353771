import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CardContent,
    CardMedia,
    Chip,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Link,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";

// const CustomAccordionDetails = styled(AccordionDetails)({
//     padding: 0,
//   });

export default function ProductCard() {
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(120);

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    useEffect(() => {
        setPrice(120 * quantity);
    }, [quantity]);

    return (
        <Accordion>
            <AccordionSummary aria-controls="panel2-content" id="panel2-header">
                {/* <Card sx={{ display: "flex", flexDirection: "column", width: "100%", position: "relative" }}> */}
                {/* Dark Gray Header with Logo */}
                <Box
                    sx={{
                        borderRadius: "5px 5px 0 0",
                        height: "35px",
                        backgroundColor: "#182434",
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "center",
                        padding: "4px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        zIndex: 0, // Make sure it's above the image
                    }}
                >
                    <img src="/images/mock/goodyear-logo.png" alt="Logo" style={{ height: "500%" }} />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", mt: 1 }}>
                    {/* Product Image */}
                    <CardMedia
                        component="img"
                        sx={{ width: 151, position: "relative", marginTop: "-10px" }} // Slight overlap
                        image="/images/mock/tire-1.png"
                        alt="Product Image"
                    />

                    {/* Product Information and Add to Cart Section */}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {/* Product Information */}
                        <CardContent sx={{ flexGrow: 1, pb: 0 }}>
                            <Typography variant="h6" fontWeight="bold" sx={{ mb: 0 }}>
                                <Link
                                    href="/portal/tire-quote/tires/1"
                                    underline="hover"
                                    color="inherit"
                                    sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                            color: "dimgray",
                                        },
                                    }}
                                >
                                    Tire Name
                                </Link>
                            </Typography>
                            <Chip label="OEM" color="primary" size="small" sx={{}} />
                            <Box>
                                <Typography variant="caption" color="textSecondary" sx={{ display: "inline" }}>
                                    Size:
                                </Typography>{" "}
                                <Typography variant="body2" color="textSecondary" sx={{ display: "inline" }}>
                                    215/65 R16
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="caption" color="textSecondary" sx={{ display: "inline" }}>
                                    SKU:
                                </Typography>{" "}
                                <Typography variant="body2" color="textSecondary" sx={{ display: "inline" }}>
                                    SKU: 12345ABC
                                </Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontWeight="bold"
                                        sx={{ display: "inline" }}
                                    >
                                        ${Math.floor(price)}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontWeight="bold"
                                        sx={{ fontSize: "0.75em", display: "inline" }}
                                    >
                                        .{(price % 1).toFixed(2).substring(2)}
                                    </Typography>
                                    <Typography variant="subtitle2" color="textSecondary" sx={{ lineHeight: 0.5 }}>
                                        PRICE
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" sx={{ display: "inline", color: "#182434" }}>
                                        ${Math.floor(price)}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontSize: "0.75em", display: "inline", color: "#182434" }}
                                    >
                                        .{(price % 1).toFixed(2).substring(2)}
                                    </Typography>
                                    <Typography variant="subtitle2" color="textSecondary" sx={{ lineHeight: 0.5 }}>
                                        RETAIL
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" sx={{ display: "inline", color: "#182434" }}>
                                        ${Math.floor(price)}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        sx={{ fontSize: "0.75em", display: "inline", color: "#182434" }}
                                    >
                                        .{(price % 1).toFixed(2).substring(2)}
                                    </Typography>
                                    <Typography variant="subtitle2" color="textSecondary" sx={{ lineHeight: 0.5 }}>
                                        INSTALL
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>

                        {/* Quantity Selector and Add to Cart Button */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                width: 120,
                                mr: 1,
                            }}
                        >
                            <FormControl size="small" sx={{ width: "100%", mb: 1 }}>
                                <InputLabel id="quantity-label">Qty</InputLabel>
                                <Select
                                    labelId="quantity-label"
                                    value={quantity}
                                    label="Qty"
                                    onChange={handleQuantityChange}
                                    fullWidth
                                >
                                    {[1, 2, 3, 4, 5].map((qty) => (
                                        <MenuItem key={qty} value={qty}>
                                            {qty}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ width: "100%", fontSize: "0.875rem", padding: "6px 8px" }}
                            >
                                Add
                            </Button>
                        </Box>
                    </Box>
                </Box>
                {/* </Card> */}
            </AccordionSummary>

            <AccordionDetails sx={{ padding: 0 }}>
                <Divider sx={{ mx: 4 }} />
                <Grid container spacing={2}>
                    {/* First column */}
                    <Grid item xs={12} md={4}>
                        <List sx={{ padding: 0 }}>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Tread Depth" secondary="10/32 inches" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Rim Diameter" secondary="15 inch" />
                            </ListItem>
                        </List>
                    </Grid>

                    {/* Second column */}
                    <Grid item xs={12} md={4}>
                        <List sx={{ padding: 0 }}>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Tread Pattern" secondary="Symmetrical" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Traction Rating" secondary="AA" />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <List sx={{ padding: 0 }}>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Rolling Resistance" secondary="Low" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText
                                    primary="Tread Pattern"
                                    secondary={
                                        <img
                                            src="/images/mock/AllTerrainSymbol.png"
                                            alt="all terrain"
                                            style={{ width: "40px", height: "auto" }}
                                        />
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
