import React, { useEffect, useState } from "react";
import { DataTablePagination } from "../../../../components/DataTable/DataTablePagination";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { DataTableFilters } from "../../../../components/DataTable/DataTableFilters";
import fetchData from "../../../../Helpers/apiCalls";
import settings from "../../../../settings/api";
import { useNavigate, useParams } from "react-router-dom";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import ActivityLogDisplay from "../../../../components/ActivityLogDisplay";
import dayjs from "dayjs";

const Activity = () => {
    const { id } = useParams();
    // const navigate = useNavigate();
    const [activities, setActivities] = useState([]);
    const [openViewActivity, setOpenViewActivity] = useState(false);
    const [activityId, setActivityId] = useState("");

    const columns = [
        {
            formatted: (row) => {
                return (
                    <Typography noWrap={true} variant="body2">
                        {row.logId}
                    </Typography>
                );
            },
            field: "logId",
            name: "Log ID",
            width: "100px",
        },
        {
            formatter: (row) => (
                <div>
                    <Typography sx={{ whiteSpace: "nowrap" }} variant="subtitle2">
                        {row.userFirst} {row.userLast}
                    </Typography>
                    {/* <Typography color="text.secondary" variant="body2">
                        {row.userId}
                    </Typography> */}
                </div>
            ),
            name: "User",
            width: "120px",
        },
        { field: "details", name: "Details", width: "250px" },
        { field: "changes", name: "Changes", width: "150px" },
        {
            formatter: (row) => (
                <div>
                    <Typography variant="body2">{dayjs(row.createdUtc).format("DD MMM, YYYY")}</Typography>
                    <Typography color="text.secondary" variant="body2">
                        {dayjs(row.createdUtc).format("hh:mm a")}
                    </Typography>
                </div>
            ),
            field: "createdUtc",
            name: "Created",
            width: "100px",
        },
        {
            formatter: (row) => {
                return (
                    <Tooltip placement="top" title="Manage Organization">
                        <IconButton
                            onClick={() => {
                                setActivityId(row.logId);
                                setOpenViewActivity(true);
                            }}
                        >
                            <EyeIcon />
                        </IconButton>
                    </Tooltip>
                );
            },
            name: "View",
            hideName: true,
            width: "100px",
            align: "center",
        },
    ];

    useEffect(() => {
        fetchActivities();

        return () => {};
    }, []);

    const fetchActivities = async () => {
        const result = await fetchData(settings.api(id).tpoScheduleActivity);
        console.log(result);

        if (!result.error) {
            setActivities(result.data);
        }
    };

    return (
        <div>
            {(activities?.filteringOptions || activities?.sortingOptions) && (
                <>
                    <DataTableFilters
                        filters={activities?.filteringOptions}
                        sorts={activities?.sortingOptions}
                        initialSortDir={activities?.sortingOptions[0]?.field}
                    />
                    <Divider />
                </>
            )}
            {activities?.items?.length > 0 ? (
                <DataTable columns={columns} rows={activities?.items} />
            ) : (
                <Box sx={{ p: 3 }}>
                    <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                        No records found
                    </Typography>
                </Box>
            )}

            <DataTablePagination
                count={activities?.totalItems}
                pageNumber={activities?.pageNumber}
                pageSize={activities?.pageSize}
            />
            <ActivityLogDisplay open={openViewActivity} handleOpen={(o) => setOpenViewActivity(o)} logId={activityId} />
        </div>
    );
};

export default Activity;
