import React, { useEffect, useState } from "react";
import fetchData from "../../../Helpers/apiCalls";
import settings from "../../../settings/api";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Icon,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { connect } from "react-redux";
import { updateMenuType } from "../../../settings/redux/componentsActions";
import { Gear as GearIcon } from "@phosphor-icons/react/dist/ssr/Gear";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";

function MultiOrgList(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [organizations, setOrganizations] = useState({});

    const columns = [
        {
            // formatter: (row) => <Avatar alt={row.organizationName} src={row.logoSmallUrl}>{row.organizationName}</Avatar>,
            formatter: (row) => (
                <Stack direction={"row"} spacing={1} sx={{ alignItems: "center" }}>
                    <img alt={row.organizationName} src={row.logoSmallUrl} />
                    <Typography variant="subtitle1">{row.organizationName}</Typography>
                </Stack>
            ),
            name: "Organization",
            width: "250px",
        },
        { field: "organizationType", name: "Type", width: "100px" },
        {
            formatter: (row) => {
                return (
                    <>
                        <Tooltip placement="top" title="Manage Organization">
                            <IconButton
                                onClick={() => {
                                    props.updateMenuType("orgAdmin");
                                    localStorage.setItem("menuType", "orgAdmin");
                                    navigate(row.pageUrl);
                                }}
                            >
                                <GearIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title="View Organization">
                            <IconButton
                                onClick={() => {
                                    navigate(`/admin/global/organizations/${row.organizationId}`);
                                }}
                            >
                                <EyeIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
            name: "Actions",
            width: "60px",
            align: "center",
        },
    ];

    useEffect(() => {
        fetchOrganizations(location.search);

        return () => {};
    }, [location.search]);

    const fetchOrganizations = async (filters = "") => {
        const url = filters !== "" ? settings.api().globalOrganizations + filters : settings.api().globalOrganizations;

        const result = await fetchData(url, "GET");
        if (!result.error) {
            setOrganizations(result.data);
        }
    };

    // console.log(organizations);

    return (
        <div>
            {/* <h1>List of Organizations</h1>
            <p>
                Lists organizations that the system administrator has access to. Clicking on the Edit button next to any
                organization, will take the user to the Portal Admin view for that organization and allow the user to
                fully manage the organization without needing to be a member.
            </p> */}
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <Icon>corporate_fare</Icon>
                        </Avatar>
                    }
                    title={<Typography variant="h6">Organizations</Typography>}
                />

                <CardContent>
                    {(organizations?.filteringOptions || organizations?.sortingOptions) && (
                        <DataTableFilters
                            filters={organizations?.filteringOptions}
                            sorts={organizations?.sortingOptions}
                            initialSortDir={organizations?.sortingOptions[0]?.field}
                        />
                    )}
                    {organizations?.items?.length > 0 ? (
                        <DataTable columns={columns} rows={organizations?.items} />
                    ) : (
                        <Box sx={{ p: 3 }}>
                            <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                                No records found
                            </Typography>
                        </Box>
                    )}

                    <DataTablePagination
                        count={organizations?.totalItems}
                        pageNumber={organizations?.pageNumber}
                        pageSize={organizations?.pageSize}
                    />
                </CardContent>
            </Card>
        </div>
    );
}
const mapStateToProps = (state) => {
    // return {
    // };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateMenuType: (menuType) => {
            dispatch(updateMenuType(menuType));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiOrgList);
