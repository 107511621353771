import {
    Avatar,
    Box,
    Button,
    Card,
    CardHeader,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import { useNavigate } from "react-router-dom";
import { Invoice as InvoiceIcon } from "@phosphor-icons/react/dist/ssr/Invoice";
import { useTranslation } from "react-i18next";

export default function QuickQuote() {
    const subdomain = getSubdomain();
    const domain = getDomain();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedValues, setSelectedValues] = useState({
        year: "",
        make: "",
        model: "",
        trim: "",
        option: "",
    });
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [trims, setTrims] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchData = (url, method, setState) => {
        fetch(url, {
            method,
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((res) => {
                // console.log(res);

                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .then((data) => setState(data))
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        fetchData(settings.api().vehicleYear, "GET", setYears);
    }, []);

    const handleSelect = (field, value, linkRel, setState, array) => {
        const resetFields = {
            year: { make: "", model: "", trim: "", option: "" },
            make: { model: "", trim: "", option: "" },
            model: { trim: "", option: "" },
            trim: { option: "" },
        };

        setSelectedValues((prevValues) => ({
            ...prevValues,
            [field]: value,
            ...resetFields[field],
        }));

        // const link = value?.links.find((link) => link.rel === linkRel);
        const propertyName = field === "year" ? field : field + "Id";
        // console.log(propertyName, value, array);

        const link = array // Ensure the link is derived properly
            .find((item) => item[propertyName] === value)
            ?.links.find((link) => link.rel === linkRel);

        if (link) {
            fetchData(settings.api().host + link.href, link.method, setState);
        }
    };

    const handleSelectYear = (e) => handleSelect("year", e.target.value, "vehicleMakes", setMakes, years);
    const handleSelectMake = (e) => handleSelect("make", e.target.value, "vehicleModels", setModels, makes);
    const handleSelectModel = (e) => handleSelect("model", e.target.value, "vehicleTrims", setTrims, models);
    const handleSelectTrim = (e) => handleSelect("trim", e.target.value, "vehicleOptions", setOptions, trims);

    return (
        <Card sx={{ height: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar>
                        <InvoiceIcon fontSize="large" />
                    </Avatar>
                }
                title={<Typography variant="h6">Quick Quote</Typography>}
            />
            <Box sx={{ px: 2, pb: 2 }}>
                <Typography gutterBottom sx={{ fontSize: "18px" }}>
                    Search Vehicle
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                        // flexDirection: { xs: "column", md: "row" }
                    }}
                >
                    <FormControl sx={{ flexGrow: 1, minWidth: "150px" }} size="small">
                        <InputLabel id="year-select-label">{t("tireService.vehicle_year", "Year")}</InputLabel>
                        <Select
                            labelId="year-select-label"
                            value={selectedValues.year}
                            label="Year"
                            onChange={(e) => handleSelectYear(e)}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: 300,
                                    },
                                },
                            }}
                        >
                            {years.length > 0 ? (
                                years.map((y) => (
                                    <MenuItem key={y.year} value={y.year}>
                                        {y.year}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    Loading...
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ flexGrow: 1, minWidth: "150px" }}
                        size="small"
                        disabled={selectedValues.year === ""}
                    >
                        <InputLabel id="make-select-label">{t("tireService.vehicle_make", "Make")}</InputLabel>
                        <Select
                            labelId="make-select-label"
                            value={selectedValues.make || ""}
                            label="Make"
                            onChange={(e) => handleSelectMake(e)}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: 300,
                                    },
                                },
                            }}
                        >
                            {makes.length > 0 ? (
                                makes.map((m) => (
                                    <MenuItem key={m.makeId} value={m.makeId}>
                                        {m.makeName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    Loading...
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ flexGrow: 1, minWidth: "150px" }}
                        size="small"
                        disabled={selectedValues.make === ""}
                    >
                        <InputLabel id="model-select-label">{t("tireService.vehicle_model", "Model")}</InputLabel>
                        <Select
                            labelId="model-select-label"
                            value={selectedValues.model || ""}
                            label="Model"
                            onChange={(e) => handleSelectModel(e)}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: 300,
                                    },
                                },
                            }}
                        >
                            {models.length > 0 ? (
                                models.map((m) => (
                                    <MenuItem key={m.modelId} value={m.modelId}>
                                        {m.modelName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    Loading...
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ flexGrow: 1, minWidth: "150px" }}
                        size="small"
                        disabled={selectedValues.model === ""}
                    >
                        <InputLabel id="trim-select-label">{t("tireService.vehicle_trim", "Trim")}</InputLabel>
                        <Select
                            labelId="trim-select-label"
                            value={selectedValues.trim || ""}
                            label="Trim"
                            onChange={(e) => handleSelectTrim(e)}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: 300,
                                    },
                                },
                            }}
                        >
                            {trims.length > 0 ? (
                                trims.map((t) => (
                                    <MenuItem key={t.trimId} value={t.trimId}>
                                        {t.trimName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    Loading...
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl
                        sx={{ flexGrow: 1, minWidth: "150px" }}
                        size="small"
                        disabled={selectedValues.trim === ""}
                    >
                        <InputLabel id="option-select-label">{t("tireService.vehicle_option", "Option")}</InputLabel>
                        <Select
                            labelId="option-select-label"
                            // value={age}
                            label="Option"
                            // onChange={handleChange}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: 300,
                                    },
                                },
                            }}
                        >
                            {options.length > 0 ? (
                                options.map((t) => (
                                    <MenuItem key={t.trimId} value={t.trimId}>
                                        {t.trimName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" disabled>
                                    Loading...
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Box>
                        <Button variant="outlined" sx={{ mr: 2 }}>
                            Clear
                        </Button>
                        <Button variant="contained" onClick={() => navigate("/portal/tire-quote")}>
                            Search
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
}
