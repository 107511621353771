import React, { useState } from "react";
import { useEffect } from "react";
import settings from "../../../settings/api";
import fetchData from "../../../Helpers/apiCalls";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataTable } from "../../../components/DataTable/DataTable";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import { CalendarDots as CalendarDotsIcon } from "@phosphor-icons/react/dist/ssr/CalendarDots";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import dayjs from "dayjs";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import * as P from "@phosphor-icons/react";
import { Eye as EyeIcon } from "@phosphor-icons/react/dist/ssr/Eye";
import { alpha } from '@mui/material/styles';

function LinearProgressWithLabel(props) {
    return (
        <Box>
            <Box sx={{ width: "100%" }}>
                <LinearProgress
                    variant="determinate"
                    color={props.status.label === "Active" ? "success" : "error"}
                    value={props.value}
                    sx={{
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: props.statusColor, // Bar color
                        },
                        backgroundColor: alpha(props.statusColor, 0.3), // Track color
                        }}

                    // {...props}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="body2" sx={{ color: "text.secondary" }} display={"inline"}>
                    {dayjs(props.startDate).format("YYYY-MM-D")}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }} display={"inline"}>
                    {dayjs(props.endDate).format("YYYY-MM-D")}
                </Typography>
            </Box>
        </Box>
    );
}

const rows = [
    {
        status: "ACTIVE",
        type: "Tire",
        title: "2025 Spring Tire Booking",
        startDate: "24-01-12",
        endDate: "24-01-25",
        progress: 56,
        previousPurchase: "-",
        previousBooked: "-",
        currentUnitsBooked: "5",
        action: "edit",
    },
    {
        status: "something",
        type: "Package",
        title: "2025 Spring Package Booking",
        startDate: "24-01-12",
        endDate: "24-01-25",
        progress: 100,
        previousPurchase: "-",
        previousBooked: "-",
        currentUnitsBooked: "3",
        action: "edit",
    },
];

const columns = [
    {
        formatter: (row) => {
            // const start = dayjs(row.startDate);
            // const end = dayjs(row.endDate);
            // // const totalDays = end.diff(start, "d") + 1;
            // const today = dayjs();
            // // const daysPassed = today.diff(start, "d");
            // const progress = today > end ? "CLOSED" : "ACTIVE";

            // const mapping = {
            //     ACTIVE: { label: "Active", color: "#15b79f" },
            //     CLOSED: { label: "Closed", color: "#f04438" },
            // };
            // const { label, color } = mapping[row.status] ?? { label: "Unknown", icon: null, color: "secondary" };
            // const { label, color } = mapping[progress] ?? { label: "Unknown", icon: null, color: "secondary" };
            const PIcon = P[row.status.icon];
            return (
                <Chip
                    // label={label}
                    label={row.status.label}
                    // sx={{ bgcolor: color, color: "#FFF", borderColor: color }}
                    sx={{ bgcolor: row.status.color, color: "#FFF", borderColor: row.status.color }}
                    size="small"
                    variant="outlined"
                    icon={<PIcon color={"#FFF"} weight="fill" />}
                />
            );
        },
        name: "Status",
        width: "80px",
        align: "center",
    },
    { field: "productName", name: "Type", width: "80px" },
    {
        formatter(row) {
            return (
                <Stack>
                    <Typography variant="body2">{row.title}</Typography>
                    <Typography color="text.secondary" variant="body2" noWrap={true}>
                        {row.description}
                    </Typography>
                </Stack>
            );
        },
        name: "Title",
        width: "200px",
    },
    {
        formatter(row) {
            const start = dayjs(row.startDate);
            const end = dayjs(row.endDate);
            const totalDays = end.diff(start, "d") + 1;
            const today = dayjs();
            const daysPassed = today.diff(start, "d");
            const progress = today > end ? 100 : (daysPassed / totalDays) * 100;

            return (
                <LinearProgressWithLabel
                    value={progress}
                    daysLeft={row.daysLeft}
                    startDate={row.startDate}
                    endDate={row.endDate}
                    status={row.status}
                    statusColor={row.status.color}
                />
            );
        },
        name: "Start & End Date",
        width: "200px",
    },
    { field: "scheduleTypeName", name: "Schedule", width: "80px", align: "center" },
    { field: "organizationName", name: "Organization", width: "100px", align: "center" },
    { field: "totalSites", name: "Total Sites", width: "100px", align: "center" },
    { field: "sitesPending", name: "Pending", width: "100px", align: "center" },
    { field: "sitesInProgress", name: "In Progress", width: "100px", align: "center" },
    { field: "sitesCompleted", name: "Completed", width: "100px", align: "center" },
    {
        formatter: (row) => {
            return (
                <Tooltip title="View Schedule" placement="top">
                    <IconButton component={RouterLink} to={row.pageUrl}>
                        <EyeIcon />
                    </IconButton>
                </Tooltip>
            );
        },
        name: "View",
        hideName: true,
        width: "80px",
        align: "center",
    },
];

export default function TPOSchedules() {
    const [schedules, setSchedules] = useState();
    const location = useLocation();

    useEffect(() => {
        fetchTpoSchedules(location.search);

        return () => {};
    }, [location.search]);
    
    const fetchTpoSchedules = async (filters = "") => {
        const url = filters !== "" ? settings.api().tpoSchedules + filters : settings.api().tpoSchedules;
        const result = await fetchData(url, "GET");
        if (!result.error) {
             setSchedules(result.data);
        }
       
    };

    return (
        <div>
            {/* <h1>TPO Schedules</h1> */}
            <Stack spacing={2}>
                <Grid container spacing={1}>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1">WINTER TIRE BOOKING</Typography>
                                <Typography variant="subtitle1">OPEN NOW</Typography>
                                <Typography variant="h2" fontWeight={500}>
                                    6
                                </Typography>
                                <Typography variant="subtitle1">Days left</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1">SPRING TIRE BOOKING</Typography>
                                <Typography variant="subtitle1">COMING SOON</Typography>
                                <Typography variant="h2" fontWeight={500}>
                                    30
                                </Typography>
                                <Typography variant="subtitle1">Days to booking start</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={6}>
                        <Card sx={{ height: "196px" }}>
                            <CardContent
                            // sx={{height: '156px'}}
                            ></CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Box sx={{ pl: 1 }}>
                    <Card>
                        <CardHeader
                            title={"Booking Schedules"}
                            avatar={
                                <Avatar>
                                    <CalendarDotsIcon fontSize="x-large" />
                                </Avatar>
                            }
                            action={
                                <Button
                                    sx={{ mt: 0 }}
                                    variant="contained"
                                    startIcon={<PlusIcon />}
                                    size="small"
                                    component={RouterLink}
                                    to={"/admin/global/tpo/schedules/add"}
                                >
                                    Create
                                </Button>
                            }
                        />
                        {(schedules?.filteringOptions.length > 0 || schedules?.sortingOptions.length > 0) && (
                            <><DataTableFilters filters={schedules?.filteringOptions} sorts={schedules?.sortingOptions} /><Divider /></>
                        )}
                        {schedules?.items.length > 0 ? (
                            <DataTable
                                columns={columns}
                                // rows={rows}
                                rows={schedules?.items}
                            />
                        ) : (
                            <Box sx={{ p: 3 }}>
                              
                                <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                                    No records found
                                </Typography>
                            </Box>
                        )}

                        <DataTablePagination
                            count={schedules?.totalItems}
                            pageSize={schedules?.pageSize}
                            pageNumber={schedules?.pageNumber}
                        />
                    </Card>
                </Box>
            </Stack>
        </div>
    );
}
