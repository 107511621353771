import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Avatar, Box, Card, CardHeader, Chip, Link, Stack, Typography } from "@mui/material";
import { DataTable } from "../../../components/DataTable/DataTable";
import { dayjs } from "../../../Helpers/dayjs";
import { Invoice as InvoiceIcon } from "@phosphor-icons/react/dist/ssr/Invoice";
import * as P from "@phosphor-icons/react";

const orders = [
    {
        id: "ORD-005",
        customer: { name: "Penjani Inyene", avatar: "/assets/avatar-4.png", email: "penjani.inyene@domain.com" },
        lineItems: 1,
        paymentMethod: { type: "visa", last4: "4011" },
        currency: "USD",
        totalAmount: 56.7,
        status: "pending",
        createdAt: dayjs().subtract(3, "hour").toDate(),
    },
    {
        id: "ORD-004",
        customer: { name: "Jie Yan", avatar: "/assets/avatar-8.png", email: "jie.yan@domain.com" },
        lineItems: 1,
        paymentMethod: { type: "amex", last4: "5678" },
        currency: "USD",
        totalAmount: 49.12,
        status: "completed",
        createdAt: dayjs().subtract(6, "hour").toDate(),
    },
    {
        id: "ORD-003",
        customer: { name: "Fran Perez", avatar: "/assets/avatar-5.png", email: "fran.perez@domain.com" },
        lineItems: 2,
        paymentMethod: { type: "applepay" },
        currency: "USD",
        totalAmount: 18.75,
        status: "canceled",
        createdAt: dayjs().subtract(7, "hour").toDate(),
    },
    {
        id: "ORD-002",
        customer: { name: "Carson Darrin", avatar: "/assets/avatar-3.png", email: "carson.darrin@domain.com" },
        lineItems: 1,
        paymentMethod: { type: "googlepay" },
        currency: "USD",
        totalAmount: 49.99,
        status: "canceled",
        createdAt: dayjs().subtract(1, "hour").subtract(1, "day").toDate(),
    },
    {
        id: "ORD-001",
        customer: { name: "Miron Vitold", avatar: "/assets/avatar-1.png", email: "miron.vitold@domain.com" },
        lineItems: 2,
        paymentMethod: { type: "mastercard", last4: "4242" },
        currency: "USD",
        totalAmount: 94.01,
        status: "completed",
        createdAt: dayjs().subtract(3, "hour").subtract(1, "day").toDate(),
    },
];

const columns = [
    {
        formatter: (row) => (
            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                <Box
                    sx={{
                        bgcolor: "var(--mui-palette-background-level1)",
                        borderRadius: 1.5,
                        flex: "0 0 auto",
                        p: "4px 8px",
                        textAlign: "center",
                    }}
                >
                    <Typography variant="caption">{dayjs(row.createdAt).format("MMM").toUpperCase()}</Typography>
                    <Typography variant="h6">{dayjs(row.createdAt).format("D")}</Typography>
                </Box>
                <div>
                    <Link
                        color="text.primary"
                        component={RouterLink}
                        // href={paths.dashboard.orders.preview("1")}
                        sx={{ cursor: "pointer" }}
                        variant="subtitle2"
                    >
                        {row.id}
                    </Link>
                    <Typography color="text.secondary" variant="body2">
                        {row.lineItems} products •{" "}
                        <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                            {new Intl.NumberFormat("en-US", { style: "currency", currency: row.currency }).format(
                                row.totalAmount
                            )}
                        </Box>
                    </Typography>
                </div>
            </Stack>
        ),
        name: "Order",
        width: "250px",
    },
    {
        formatter: (row) => (
            <Stack direction="row" spacing={2}>
                <div>
                    <Typography variant="subtitle2">{row.customer.name}</Typography>
                    <Typography color="text.secondary" variant="body2">
                        {row.customer.email}
                    </Typography>
                </div>
            </Stack>
        ),
        name: "Customer",
        width: "250px",
    },
    {
        formatter: (row) => {
            // const mapping = {
            //     pending: {
            //         label: "Pending",
            //         icon: <ClockIcon color="#fb9c0c" weight="fill" />,
            //     },
            //     completed: {
            //         label: "Completed",
            //         icon: <CheckCircleIcon color="#15b79f" weight="fill" />,
            //     },
            //     canceled: {
            //         label: "Canceled",
            //         icon: <XCircleIcon color="#f04438" weight="fill" />,
            //     },
            //     rejected: { label: "Rejected", icon: <MinusIcon color="#f04438" /> },
            // };
            const mapping = {
                pending: {
                    label: "Pending",
                    icon: "Clock",
                    color: "#fb9c0c",
                },
                completed: {
                    label: "Completed",
                    icon: "CheckCircle",
                    color: "#15b79f",
                },
                canceled: {
                    label: "Canceled",
                    icon: "XCircle",
                    color: "#f04438",
                },
                rejected: {
                    label: "Rejected",
                    icon: "Minus",
                    color: "#f04438",
                },
            };

            const { label, icon, color } = mapping[row.status] ?? { label: "Unknown", icon: null };
            const PIcon = P[icon];

            return <Chip icon={<PIcon color={color} weight="fill" />} label={label} size="small" variant="outlined" />;
        },
        name: "Status",
        width: "100px",
    },
];

export function SimpleOrderTable() {
    return (
        <Card sx={{ height: "100%" }}>
            <CardHeader
                avatar={
                    <Avatar>
                        <InvoiceIcon fontSize="large" />
                    </Avatar>
                }
                title={<Typography variant="h6">Recent Orders</Typography>}
            />
            {/* <Box sx={{ px: 2, pt: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Recent Orders
                </Typography>
            </Box> */}
            <Box sx={{ overflowX: "auto" }}>
                <OrdersTable rows={orders} />
            </Box>
        </Card>
    );
}

function OrdersTable({ rows }) {
    // const { selected, deselectAll, deselectOne, selectAll, selectOne } = useOrdersSelection();

    return (
        <React.Fragment>
            <DataTable columns={columns} rows={rows} dense={true} />
            {!rows.length ? (
                <Box sx={{ p: 3 }}>
                    <Typography color="textSecondary" sx={{ textAlign: "center" }} variant="body2">
                        No orders found
                    </Typography>
                </Box>
            ) : null}
        </React.Fragment>
    );
}
