import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
// import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { User as UserIcon } from "@phosphor-icons/react/dist/ssr/User";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { useCustomersSelection } from "../../components/DataTable/customers-selection-context";
import { dayjs } from "../../Helpers/dayjs";
import { DataTable } from "../../components/DataTable/DataTable";
import { Button, Card, CardHeader, Divider } from "@mui/material";
import { DataTablePagination } from "../../components/DataTable/DataTablePagination";
import { DataTableFilters } from "../../components/DataTable/DataTableFilters";
import { CustomersSelectionProvider } from "../../components/DataTable/customers-selection-context";
import { getInitials } from "../../Helpers/GetInitialsFromName";
import { useEffect } from "react";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import { Users as UsersIcon } from "@phosphor-icons/react/dist/ssr/Users";
import { useTranslation } from "react-i18next";

export function UsersList() {
    const { deselectAll, deselectOne, selectAll, selectOne, selected } = useCustomersSelection();
    const { t } = useTranslation();
    const subdomain = getSubdomain();
    const domain = getDomain();
    const [users, setUsers] = useState([]);
    const location = useLocation();

    const columns = [
        {
            formatter: (row) => (
                <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                    <Avatar src={row.avatar}>{getInitials(row.name)}</Avatar>{" "}
                    <div>
                        <Link
                            color="inherit"
                            component={RouterLink}
                            // to={`/admin/organizations/${orgId}/users/1`}
                            to={row.pageUrl}
                            sx={{ whiteSpace: "nowrap" }}
                            variant="subtitle2"
                        >
                            {row.firstName} {row.lastName}
                        </Link>
                        <Typography color="text.secondary" variant="body2">
                            {row.email}
                        </Typography>
                    </div>
                </Stack>
            ),
            name: t("base.userView_site_users_column_name", "Name"),
            width: "250px",
        },
        { field: "phoneMobile", name: t("base.userView_site_users_column_phone", "Phone Number"), width: "150px" },
        {
            formatter(row) {
                const date = dayjs(row.lastSessionUtc).format("MMM D, YYYY h:mm A");
                return date !== "Invalid Date" ? date : "N/A";
            },
            name: t("base.userView_site_users_column_signon", "Last Signon"),
            width: "200px",
        },
        {
            formatter: (row) => {
                const mapping = {
                    USER: {
                        label: "User",
                        icon: <UserIcon color="#15b79f" weight="fill" />,
                    },
                    MANAGER: { label: "Manager", icon: <UserIcon color="#fb9c0c" weight="fill" /> },
                    ADMIN: {
                        label: "Admin",
                        icon: <UserIcon color="#f04438" weight="fill" />,
                    },
                };
                const { label, icon } = mapping[row.userRoleCode] ?? { label: "Unknown", icon: null };

                return <Chip icon={icon} label={label} size="small" variant="outlined" />;
            },
            name: t("base.userView_site_users_column_role", "Role"),
            width: "100px",
            align: "center",
        },
        {
            formatter: (row) => {
                return (
                    <IconButton component={RouterLink} to={row.pageUrl}>
                        <PencilSimpleIcon />
                    </IconButton>
                );
                // }
            },
            name: "View",
            hideName: true,
            width: "100px",
            align: "center",
        },
    ];

    useEffect(() => {
        fetchUsers(location.search);

        return () => {};
    }, [location.search]);

    const fetchUsers = (filters = "") => {
        const url = filters !== "" ? settings.api().users + filters : settings.api().users;

        fetch(url, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    const error = new Error(res.error);
                    throw error;
                }
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setUsers(data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Box
            sx={{
                maxWidth: "var(--Content-maxWidth)",
                m: "var(--Content-margin)",
                p: "var(--Content-padding)",
                width: "var(--Content-width)",
            }}
        >
            <Stack spacing={1}>
                <Card>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ alignItems: "flex-start" }}>
                        <Box sx={{ flex: "1 1 auto" }}>
                            <CardHeader
                                avatar={
                                    <Avatar>
                                        <UsersIcon fontSize="large" />
                                    </Avatar>
                                }
                                title={<Typography variant="h6">{t("base.userView_site_title_users", "Users")}</Typography>}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                            <Button startIcon={<PlusIcon />} variant="contained" color="primary">
                                {t("base.button_add", "Add")}
                            </Button>
                        </Box>
                    </Stack>
                    {users?.filteringOptions && (
                        <DataTableFilters
                            sorts={users?.sortingOptions}
                            filters={users?.filteringOptions}
                            initialSortDir={users?.sortingOptions[0].field}
                        />
                    )}
                    <Divider />
                    <Box sx={{ overflowX: "auto" }}>
                        <DataTable columns={columns} rows={users?.items} />
                    </Box>
                    <Divider />
                    {users?.totalItems > 0 && (
                        <DataTablePagination
                            count={users?.totalItems}
                            pageSize={users?.pageSize}
                            pageNumber={users?.pageNumber}
                        />
                    )}
                </Card>
            </Stack>
        </Box>
    );
}
