import React, { useState } from "react";
import { Avatar, Box, Button, Card, CardContent, CardHeader, IconButton, Paper, Stack, TextField } from "@mui/material";
import { SettingsDrawer } from "../../../../components/TranslationDrawer/TranslationDrawer";

import { Globe as GlobeIcon } from "@phosphor-icons/react/dist/ssr/Globe";
import { BookBookmark as BookBookmarkIcon } from "@phosphor-icons/react/dist/ssr/BookBookmark";

export default function OrgAdminTrainingCourses() {
    const [openTranslationDrawer, setOpenTranslationDrawer] = useState(false);
    const [translationField, setTranslationField] = useState({});

    return (
        <div>
            <h1>Org Admin Training Courses</h1>
            {/* <Paper sx={{ p: 2 , width: 800}}> */}
            <Card sx={{ width: 800 }}>
                <CardHeader
                    avatar={
                        <Avatar>
                            <BookBookmarkIcon fontSize="var(--Icon-fontSize)" />
                        </Avatar>
                    }
                    title="Create Course"
                />

                <CardContent>
                    <Stack spacing={2}>
                        <Box display="flex" alignItems="flex-start" gap={1}>
                            <TextField size="small" label="Title" fullWidth>
                                Something
                            </TextField>
                            <IconButton
                                onClick={() => {
                                    setOpenTranslationDrawer(true);
                                }}
                            >
                                <GlobeIcon />
                            </IconButton>
                        </Box>
                        <Box display="flex" alignItems="flex-start" gap={1}>
                            <TextField
                                size="small"
                                label="Description"
                                fullWidth
                                multiline
                                rows={8}
                                defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
                            />
                            <IconButton
                                onClick={() => {
                                    setOpenTranslationDrawer(true);
                                }}
                            >
                                <GlobeIcon />
                            </IconButton>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
            {/* </Paper> */}
            <SettingsDrawer
                onClose={() => {
                    setOpenTranslationDrawer(false);
                }}
                open={openTranslationDrawer}
                field={translationField}
                // onReset={handleReset}
                // onUpdate={handleUpdate}
                // values={settings}
            />
        </div>
    );
}
