import React from "react";
import { Box, Card, CardContent, Divider, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import settings from "../settings/api";
import { getDomain, getSubdomain } from "../Helpers/getSubdomain";
import { useState } from "react";

export default function Versions() {
    const token = localStorage.getItem("authToken");
    const subdomain = getSubdomain();
    const domain = getDomain();
    const [versions, setVersions] = useState([]);

    useEffect(() => {
        fetch(settings.api().versions, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.json())
            .then((data) => setVersions(data));

        return () => {};
    }, []);

    return (
        <div>
            <h1>Versions</h1>
            <Card>
                <CardContent>
                    <Stack>
                        {versions?.map((v, k) => (
                            <Box>
                                <Typography>Version: {v.version}</Typography>
                                <Typography color="textSecondary">Release Date: {v.release_date}</Typography>
                                <ul>
                                    {v.changes.map((c) => (
                                        <li>{c}</li>
                                    ))}
                                </ul>
                                <Divider sx={{ mb: 2 }} />
                            </Box>
                        ))}
                    </Stack>
                </CardContent>
            </Card>
        </div>
    );
}
