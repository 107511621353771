import React, { Suspense, lazy } from "react";
import Login from "../pages/Login";
import Home from "../pages/Home";
import PortalLayout from "../layout/Portal/Portal";
import Error404 from "../pages/404";
import Dashboard from "../pages/StandardPages/Dashboard/Dashboard";
import PrivateRoute from "../Helpers/PrivateRoute";
import Groups from "../pages/PortalAdmin/Groups/Groups";
import NewGroup from "../pages/PortalAdmin/Groups/NewGroup";
import NewEditUser from "../components/Users/NewEditUser";
import Cases from "../pages/StandardPages/Cases";
import Lessons from "../pages/StandardPages/Lessons";
import Assessments from "../pages/StandardPages/Assessments";
import MySite from "../pages/StandardPages/MySite";
import LoadingSpinner from "../components/Spinner";
import NewGroupType from "../pages/PortalAdmin/GroupTypes/NewGroupType";
import ViewUser from "../components/Users/ViewUser";
import Profile from "../pages/StandardPages/Profile";
import { UsersList } from "../components/Users/UsersList";
import TireQuotePage from "../pages/StandardPages/TireQuotePage";
import ProductDetailPage from "../pages/StandardPages/TireDetails";
import { EditSiteForm } from "../components/Users/EditSiteForm";
import UserDetails from "../components/Users/UserDetails";
import NewAssessments from "../pages/StandardPages/NewAssessments";
import AccountLayout from "../pages/StandardPages/Account";
import Notifications from "../pages/StandardPages/Notifications";
import TireQuote from "../pages/SitesAdmin/TireQuote/TireQuote";
import Pricing from "../pages/SitesAdmin/TireQuote/Pricing";
import Featured from "../pages/SitesAdmin/TireQuote/Featured";
import Promotions from "../pages/SitesAdmin/TireQuote/Promotions";
import Stock from "../pages/SitesAdmin/TireQuote/Stock";
import TPO from "../pages/SitesAdmin/TireQuote/TPO";
import Settings from "../pages/SitesAdmin/TireQuote/Settings";
import Orders from "../pages/StandardPages/TireQuotePage/Orders";
import Vehicles from "../pages/StandardPages/TireQuotePage/Vehicles";
import Tires from "../pages/StandardPages/TireQuotePage/Tires";
import Wheels from "../pages/StandardPages/TireQuotePage/Wheels";
import Packages from "../pages/StandardPages/TireQuotePage/Packages";
import Accessories from "../pages/StandardPages/TireQuotePage/Accessories";
import Reports from "../pages/StandardPages/TireQuotePage/Reports";

// sites admin
import SitesAdminDashboard from "../pages/SitesAdmin/Dashboard";
import OrgAdminDashboard from '../pages/PortalAdmin/Dashboard'
import OrgAdminSites from "../pages/PortalAdmin/Sites/Sites";
import GroupTypes from "../pages/PortalAdmin/GroupTypes/GroupTypes";
import OrgAdminTrainingSettings from "../pages/PortalAdmin/Training/Settings";
import OrgAdminAssessmentSettings from "../pages/PortalAdmin/Assessment/Settings";
import OrgAdminTACSettings from "../pages/PortalAdmin/TAC/Settings";
import MultiOrgAdminDashboard from "../pages/SystemAdmin/Dashboard";
import MultiOrgList from "../pages/SystemAdmin/Organizations/Organizations";
import OrgAdminTrainingCourses from "../pages/PortalAdmin/Training/Courses/Courses";
import OrgAdminTrainingCourseCategories from "../pages/PortalAdmin/Training/Courses/CourseCategories";
import OrgAdminTrainingLessons from "../pages/PortalAdmin/Training/Courses/Lessons";
import Order from "../pages/StandardPages/TireQuotePage/Order";
import Versions from "../pages/Versions";
import TPOSchedules from "../pages/SystemAdmin/TPO/TPOSchedules";
import TPOSettings from "../pages/SystemAdmin/TPO/TPOSettings";
import TPOBookings from "../pages/SystemAdmin/TPO/TPOBookings";
import AddEditTPOSchedule from "../pages/SystemAdmin/TPO/AddEditTPOSchedule";
import TmpPage from "../pages/TmpPage";
import TireQuoteTires from "../pages/SitesAdmin/TireQuote/Data/Tires";
import TireQuoteWheels from "../pages/SitesAdmin/TireQuote/Data/Wheels";
import TireWheelPackages from "../pages/SitesAdmin/TireQuote/Data/Packages";
import TireQuoteAccessories from "../pages/SitesAdmin/TireQuote/Data/Accessories";
import PricingTemplates from "../pages/SitesAdmin/TireQuote/Pricing/Templates";
import PortalUsersList from "../pages/PortalAdmin/Users/List";
import TPOSchedule from '../pages/SystemAdmin/TPO/Schedule/TPOSchdule'
import ProtectedRouteWrapper from "../Helpers/PrivateRoute";
import TireQuoteSettings from "../pages/PortalAdmin/TireQuote/Settings";
import SystemAdminOrganization from "../pages/SystemAdmin/Organizations/Organization";

const DashboardPage = lazy(() => import("../pages/StandardPages/Dashboard/Dashboard"));

const protectedRoute = (Component) => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <ProtectedRouteWrapper Component={Component} />
        </Suspense>
    );
};

export const routes = [
    {
        index: true,
        element: <Home />,
    },
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "portal",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <DashboardPage />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        index: true,
                        element: <TireQuotePage />,
                    },
                    {
                        path: "orders",
                        element: <Orders />,
                    },
                    {
                        path: "orders/:id",
                        element: <Order />,
                    },
                    {
                        path: "vehicles",
                        element: <Vehicles />,
                    },
                    {
                        path: "tires",
                        element: <Tires />,
                    },
                    {
                        path: "tires/:id",
                        element: <ProductDetailPage />,
                    },
                    {
                        path: "wheels",
                        element: <Wheels />,
                    },
                    {
                        path: "packages",
                        element: <Packages />,
                    },
                    {
                        path: "accessories",
                        element: <Accessories />,
                    },
                    {
                        path: "reports",
                        element: <Reports />,
                    },
                ],
            },
            {
                path: "lessons",
                element: <Lessons />,
            },
            {
                path: "assessments",
                children: [
                    {
                        index: true,
                        element: <Assessments />,
                    },
                    {
                        path: "new",
                        element: <NewAssessments />,
                    },
                ],
            },
            {
                path: "cases",
                element: <Cases />,
            },
            {
                path: "site",
                children: [
                    {
                        index: true,
                        element: <MySite />,
                    },
                    {
                        path: "edit",
                        element: <EditSiteForm />,
                    },
                    {
                        path: "add-member",
                        element: <EditSiteForm />,
                    },
                    {
                        path: "users/:id",
                        element: <UserDetails />,
                    },
                ],
            },
            {
                path: "account",
                // element: <Profile />,
                element: (
                    <AccountLayout>
                        <Profile />
                    </AccountLayout>
                ),
            },
            {
                path: "notifications",
                element: (
                    <AccountLayout>
                        <Notifications />
                    </AccountLayout>
                ),
            },
            {
                path: "user",
                element: <UserDetails />,
            },
            {
                path: "/portal/versions",
                element: <Versions />,
            },
            {
                path: "*",
                element: <Error404 />,
            },
        ],
    },
    {
        path: "admin/sites/:id",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <SitesAdminDashboard />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "data/tires",
                        element: <TireQuoteTires />,
                    },
                    {
                        path: "data/wheels",
                        element: <TireQuoteWheels />,
                    },
                    {
                        path: "data/packages",
                        element: <TireWheelPackages />,
                    },
                    {
                        path: "data/accessories",
                        element: <TireQuoteAccessories />,
                    },
                    {
                        path: "pricing",
                        element: <Pricing />,
                    },
                    {
                        path: "pricing/templates",
                        element: <PricingTemplates />,
                    },
                    {
                        path: "featured",
                        element: <Featured />,
                    },
                    {
                        path: "promotions",
                        element: <Promotions />,
                    },
                    {
                        path: "stock",
                        element: <Stock />,
                    },
                    // {
                    //     path: "tpo",
                    //     element: <TPO />,
                    // },
                    // {
                    //     path: "settings",
                    //     element: <Settings />,
                    // },
                ],
            },
            {
                path: "tpo",
                children: [
                    {
                        index: true,
                        element: <TPO />,
                    },
                    {
                        path: "settings",
                        element: <Settings />,
                    },
                ],
            },
        ],
    },
    {
        path: "admin/organizations/:id",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <OrgAdminDashboard />,
            },
            {
                path: "sites",
                children: [
                    {
                        // path: "list",
                        index: true,
                        element: <OrgAdminSites />,
                    },
                    {
                        path: ":id",
                        element: <TmpPage />,
                    },
                    {
                        path: "groups",
                        element: <Groups />,
                    },
                    {
                        path: "group-types",
                        element: <GroupTypes />,
                    },
                ],
            },
            {
                path: "users",
                element: <PortalUsersList />,
            },
            {
                path: "users/:id",
                element: <TmpPage />,
            },
            {
                path: "tire-quote",
                children: [
                    {
                        path: "settings",
                        element: <TireQuoteSettings />,
                    },
                ],
            },
            {
                path: "tpo",
                children: [
                    // {
                    //     index: true,
                    //     element: <TPO />,
                    // },
                    {
                        path: "settings",
                        element: <Settings />,
                    },
                ],
            },
            {
                path: "lms",
                children: [
                    {
                        path: "courses",
                        element: <OrgAdminTrainingCourses />,
                    },
                    {
                        path: "lessons",
                        element: <OrgAdminTrainingLessons />,
                    },
                    {
                        path: "course-categories",
                        element: <OrgAdminTrainingCourseCategories />,
                    },
                    {
                        path: "settings",
                        element: <OrgAdminTrainingSettings />,
                    },
                ],
            },
            {
                path: "assessments",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminAssessmentSettings />,
                    },
                ],
            },
            {
                path: "tac",
                children: [
                    {
                        path: "settings",
                        element: <OrgAdminTACSettings />,
                    },
                ],
            },
        ],
    },
    {
        path: "admin/global",
        element: protectedRoute(PortalLayout),
        children: [
            {
                index: true,
                element: <MultiOrgAdminDashboard />,
            },
            {
                path: "organizations",
                children: [
                    {
                        // path: "list",
                        index: true,
                        element: <MultiOrgList />,
                    },
                    {
                        path: ":id",
                        element: <SystemAdminOrganization />
                    }
                ],
            },
            // {
            //     path: "tire-quote",
                // children: [
                    // {
                    //     path: "tpo",
                    //     children: [
                    //         {
                    //             path: "schedules",
                    //             element: <TPOSchedules />,
                    //         },
                    //         {
                    //             path: "schedules/:id",
                    //             element: <TPOSchedule />,
                    //         },
                    //         {
                    //             path: "schedules/add",
                    //             element: <AddEditTPOSchedule mode={"create"} />,
                    //         },
                    //         {
                    //             path: "bookings",
                    //             element: <TPOBookings />,
                    //         },
                    //     ],
                    // },
                    // {
                    //     path: "settings",
                    //     element: <TPOSettings />,
                    // },
                // ],
            // },
            {
                path: "tpo",
                children: [
                    // {
                    //     index: true,
                    //     element: <TPO />,
                    // },
                    {
                        path: "schedules",
                        element: <TPOSchedules />,
                    },
                    {
                        path: "schedules/add",
                        element: <AddEditTPOSchedule mode={"create"} />,
                    },
                    {
                        path: "schedules/:id",
                        element: <TPOSchedule />,
                    },
                    {
                        path: "bookings",
                        element: <TPOBookings />,
                    },
                    {
                        path: "settings",
                        element: <Settings />,
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        element: <Error404 />,
    },
];
