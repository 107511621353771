import React from "react";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material";
import { useState } from "react";
import CustomTabPanel from "../../../components/CustomTabPanel";
import WidgetSetting from "../../../components/MySite/WidgetSetting";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import BootstrapInput from "../../../components/BootstrapTextField";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

// styling for available year selector
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function Settings() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [availableYearsSelected, setAvailableYearsSelected] = useState(0);
    const [availableYears] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15]);

    const [availableMakeSelected, setAvailableMakeSelected] = useState([]);
    const [availableMake] = useState(["Infiniti", "Nissan", "Mitsubushi"]);

    const [showAllMake, setShowAllMake] = useState(true);

    const handleAvailableMakeSelected = (event) => {
        const { value } = event.target;
        setAvailableMakeSelected(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    return (
        <div>
            <Card>
                <CardHeader
                    title={<Typography variant="h6">Settings</Typography>}
                    avatar={
                        <Avatar>
                            <SettingsIcon />
                        </Avatar>
                    }
                />
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="General" />
                        <Tab label="Vehicle Build" />
                        <Tab label="Widgets" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <MailOutlineIcon />
                                </Avatar>
                            }
                            title={<Typography variant="h6">Emails</Typography>}
                        />
                        <CardContent sx={{ pt: 0 }}>
                            {/* <Typography variant="subtitle1">Emails</Typography>
                            <Divider /> */}
                            <Box
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: { sm: "1fr 1fr" },
                                    gap: 2,
                                    py: 2,
                                }}
                            >
                                <FormControl variant="standard">
                                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ position: "relative" }}>
                                        Completed TPO
                                    </InputLabel>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <BootstrapInput
                                            name={"completedTPO"}
                                            type="email"
                                            placeholder="email@email.com"
                                            fullWidth
                                        />
                                        <Tooltip
                                            title="Completed TPO booking CSV files will be sent to this email."
                                            placement="top"
                                        >
                                            <InfoOutlinedIcon />
                                        </Tooltip>
                                    </Box>
                                </FormControl>
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined">Save</Button>
                        </CardActions>
                    </Card>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <Card>
                        <CardHeader title={<Typography variant="h6">Vehicle Build Settings</Typography>} />

                        <CardContent sx={{ pt: 0 }}>
                            <Box sx={{ display: "flex", alignItems: "center", p: 0, mt: 2, gap: 1 }}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-multiple-checkbox-label">Select Years Back</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        value={availableYearsSelected}
                                        onChange={(e) => setAvailableYearsSelected(parseInt(e.target.value))}
                                        input={<OutlinedInput label="Select Years Back" />}
                                    >
                                        {availableYears?.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year} {year === 0 && "(all years available)"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Tooltip
                                    title="Select the amount of years back you want available. Selecting 0 years will display all years available."
                                    placement="top"
                                >
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showAllMake}
                                            onChange={() => {
                                                setShowAllMake(!showAllMake);
                                            }}
                                        />
                                    }
                                    label={<Typography noWrap>Show All Makes</Typography>}
                                />
                                <Tooltip
                                    title="Selects all the make available. Otherwise, select the makes individually"
                                    placement="top"
                                >
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Box>
                            {!showAllMake && (
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-multiple-checkbox-label">Select Available Make</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={availableMakeSelected}
                                        onChange={handleAvailableMakeSelected}
                                        input={<OutlinedInput label="Select Available Make" />}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                    >
                                        {availableMake?.map((make) => (
                                            <MenuItem key={make} value={make}>
                                                <Checkbox checked={availableMakeSelected.includes(make)} />
                                                <ListItemText primary={make} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </CardContent>
                    </Card>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <WidgetSetting />
                    <Card sx={{ mt: 2 }}>
                        <CardHeader title={<Typography variant="h6">Vehicle Build Settings</Typography>} />
                        <CardContent sx={{ pt: 0 }}>
                            <Box sx={{ display: "flex", alignItems: "center", p: 0, mt: 2, gap: 1 }}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-multiple-checkbox-label">Select Years Back</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        value={availableYearsSelected}
                                        onChange={(e) => setAvailableYearsSelected(parseInt(e.target.value))}
                                        input={<OutlinedInput label="Select Years Back" />}
                                    >
                                        {availableYears?.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year} {year === 0 && "(all years available)"}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Tooltip
                                    title="Select the amount of years back you want available. Selecting 0 years will display all years available."
                                    placement="top"
                                >
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showAllMake}
                                            onChange={() => {
                                                setShowAllMake(!showAllMake);
                                            }}
                                        />
                                    }
                                    label={<Typography noWrap>Show All Makes</Typography>}
                                />
                                <Tooltip
                                    title="Selects all the make available. Otherwise, select the makes individually"
                                    placement="top"
                                >
                                    <InfoOutlinedIcon />
                                </Tooltip>
                            </Box>
                            {!showAllMake && (
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-multiple-checkbox-label">Select Available Make</InputLabel>
                                    <Select
                                        size="small"
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={availableMakeSelected}
                                        onChange={handleAvailableMakeSelected}
                                        input={<OutlinedInput label="Select Available Make" />}
                                        renderValue={(selected) => selected.join(", ")}
                                        MenuProps={MenuProps}
                                    >
                                        {availableMake?.map((make) => (
                                            <MenuItem key={make} value={make}>
                                                <Checkbox checked={availableMakeSelected.includes(make)} />
                                                <ListItemText primary={make} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </CardContent>
                    </Card>
                </CustomTabPanel>
            </Card>
        </div>
    );
}
