import React from "react";
import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";

const rows = [
    {
        promoName: "Tire Rebate",
        owner: "Tire Manufactorer",
        status: "Active",
        startDate: "01/10/2023",
        endDate: "01/04/2024",
        enrolled: true,
    },
    {
        promoName: "Tire Rebate",
        owner: "Tire Manufactorer",
        status: "Active",
        startDate: "01/10/2023",
        endDate: "01/04/2024",
        enrolled: true,
    },
];

const columns = [
    { field: "promoName", name: "Promotion Name", width: "200px" },
    { field: "owner", name: "Owner", width: "150px" },
    { field: "status", name: "Status", width: "150px" },
    { field: "startDate", name: "Start Date", width: "150px" },
    { field: "endDate", name: "End Date", width: "150px" },
    {
        formatter: (row) => {
            return <Typography>{row.enrolled ? "YES" : "NO"}</Typography>;
        },
        name: "Enrolled",
        width: "100px",
        align: "center",
    },
];

const sortingOptions = [
    {
        field: "date",
        label: "Date",
        order: ["asc", "desc"],
    },
];

const filteringOptions = [
    {
        field: "status",
        label: "Status",
        labelDescription: "Filter by Status",
        fieldType: "dropdown",
        values: ["Active", "Expired"],
    },
    {
        field: "type",
        label: "Types",
        labelDescription: "Filter by Type",
        fieldType: "tab",
        values: ["Active Promotions", "Tires", "Wheels", "Packages", "Accessories"],
    },
];

export default function Promotions() {
    const chartState = {
        series: [
            {
                name: "Sales",
                data: [39081, 54930, 130294, 110022, 92023, 142930],
            },
            {
                name: "Sales",
                data: [54930, 142930, 92023, 130294, 39081, 110022],
            },
        ],
        options: {
            chart: {
                type: "line",
                toolbar: {
                    tools: {
                        download: true,
                        selection: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: false,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            xaxis: {
                type: "category",
                categories: ["May", "June", "July", "August", "September", "October"],
            },
            yaxis: {
                labels: {
                    formatter: (val) => `$${val.toLocaleString()}`,
                },
            },
            tooltip: {
                x: {
                    format: "MMMM", // Display full month name in tooltip
                },
            },
        },
    };

    return (
        <div>
            <Stack spacing={2}>
                <h1>Promotions</h1>
                <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                    <Grid item md={3}>
                        <Card>
                            <CardContent
                                sx={{
                                    height: "200px",
                                    display: "grid",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                }}
                            >
                                <Typography variant="subtitle1">ACTIVE PROMOTION</Typography>
                                <Typography variant="h2" fontWeight={500} color="success.main">
                                    6
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={9}>
                        <Card>
                            <CardContent sx={{ p: 0.5, height: 200 }}>
                                <div id="chart">
                                    <ReactApexChart
                                        options={chartState.options}
                                        series={chartState.series}
                                        type="line"
                                        height={200}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Card>
                    <CardContent>
                        <DataTableFilters sorts={sortingOptions} filters={filteringOptions} />
                        <DataTable columns={columns} rows={rows} />
                        <DataTablePagination />
                    </CardContent>
                </Card>
            </Stack>
        </div>
    );
}
