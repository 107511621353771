import React from "react";

export default function MultiOrgAdminDashboard() {
    return (
        <div>
            <h1>System Administrator Dashboard</h1>

            <p>
                Dashboard will contain widgets such as graphs and reports to quickly walk a system admin to the various
                management components.
            </p>
        </div>
    );
}
