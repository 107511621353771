import React, { useState } from "react";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import { Trash as TrashIcon } from "@phosphor-icons/react/dist/ssr/Trash";
import CustomTabPanel from "../../../components/CustomTabPanel";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";

const rows = [
    {
        season: "Winter",
        appliedTo: "All Tires",
        status: "active",
        good: ["Continental", "Pirelli", "Uniroyal", "if none of the above are available use economical tires"],
        better: ["BFGoodrich", "Continental", "Perilli", "if none of the above are available use economical tires"],
        best: ["Michelin", "Continental", "Perilli", "if none of the above are available use economical tires"],
    },
];

const columns = [
    { field: "season", name: "Season", width: "80px" },
    {
        formatter: (row) => {
            const mapping = {
                active: { label: "Active", color: "success" },
                expired: { label: "Expired", color: "error" },
            };
            const { label, color } = mapping[row.status] ?? { label: "Unknown", color: "secondary" };
            return <Chip color={color} label={label} size="small" variant="soft" />;
        },
        name: "Status",
        width: "80px",
    },
    {
        formatter: (row) => {
            return (
                <Stack>
                    <Typography variant="body2">
                        {row.good.map((g, i) => (i !== row.good.length - 1 ? <li>{g}</li> : g))}
                    </Typography>
                </Stack>
            );
        },
        name: "Good",
        width: "300px",
    },
    {
        formatter: (row) => {
            return (
                <Stack>
                    <Typography variant="body2">
                        {row.better.map((g, i) => (i !== row.better.length - 1 ? <li>{g}</li> : g))}
                    </Typography>
                </Stack>
            );
        },
        name: "Better",
        width: "300px",
    },
    {
        formatter: (row) => {
            return (
                <Stack>
                    <Typography variant="body2">
                        {row.best.map((g, i) => (i !== row.best.length - 1 ? <li>{g}</li> : g))}
                    </Typography>
                </Stack>
            );
        },
        name: "Best",
        width: "300px",
    },
    {
        formatter: (row) => {
            return (
                <Box>
                    <Tooltip title="Edit Rule" placement="top">
                        <IconButton>
                            <PencilSimpleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Rule" placement="top">
                        <IconButton>
                            <TrashIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        },
        name: "Action",
        width: "100px",
        align: "center",
    },
];

const sortingOptions = [
    // {
    //     field: "date",
    //     label: "Date",
    //     order: ["asc", "desc"],
    // },
];

const filteringOptions = [
    // {
    //     field: "season",
    //     label: "Season",
    //     labelDescription: "Filter by Season",
    //     fieldType: "dropdown",
    //     values: ["All year", "Winter", "Spring"],
    // },
    {
        field: "status",
        label: "Status",
        labelDescription: "Filter by Status",
        fieldType: "tab",
        values: ["Active", "Expired"],
    },
];

const tireSizeRows = [
    {
        season: "Winter",
        appliedTo: "All Tires",
        status: "active",
        good: ["Goodyear WinterCommand Ultra (7384682)"],
        better: ["Yokohama IceGuard IG53 (112093)"],
        best: ["Michelin X-Ice Snow (87342)"],
    },
    {
        season: "Winter",
        appliedTo: "All Tires",
        status: "active",
        good: ["Goodyear WinterCommand Ultra (7384682)"],
        better: ["Yokohama IceGuard IG53 (112093)"],
        best: ["Michelin X-Ice Snow (87342)"],
    },
];

const tireSizeColumns = [
    { field: "tireSize", name: "Tire Size", width: "80px" },
    { field: "season", name: "Season", width: "80px" },
    {
        formatter: (row) => {
            const mapping = {
                active: { label: "Active", color: "success" },
                expired: { label: "Expired", color: "error" },
            };
            const { label, color } = mapping[row.status] ?? { label: "Unknown", color: "secondary" };
            return <Chip color={color} label={label} size="small" variant="soft" />;
        },
        name: "Status",
        width: "80px",
    },
    {
        formatter: (row) => {
            return (
                <Stack>
                    <Typography variant="body2">{row.good}</Typography>
                </Stack>
            );
        },
        name: "Good",
        width: "300px",
    },
    {
        formatter: (row) => {
            return (
                <Stack>
                    <Typography variant="body2">{row.better}</Typography>
                </Stack>
            );
        },
        name: "Better",
        width: "300px",
    },
    {
        formatter: (row) => {
            return (
                <Stack>
                    <Typography variant="body2">{row.better}</Typography>
                </Stack>
            );
        },
        name: "Best",
        width: "300px",
    },
    {
        formatter: (row) => {
            return (
                <Box>
                    <Tooltip title="Edit Rule" placement="top">
                        <IconButton>
                            <PencilSimpleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Rule" placement="top">
                        <IconButton>
                            <TrashIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        },
        name: "Action",
        width: "100px",
        align: "center",
    },
];
export default function Featured() {
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>
            <Card>
                <CardHeader
                    title={<Typography variant="h6">Featured</Typography>}
                    avatar={
                        <Avatar>
                            <StarIcon />
                        </Avatar>
                    }
                />
                <CardContent>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="featured category tabs">
                            <Tab label="Manufacturer" />
                            <Tab label="Tire Size" />
                            <Tab label="Vehicle" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                        <Card>
                            <DataTableFilters sorts={sortingOptions} filters={filteringOptions} />
                            <DataTable dense={true} columns={columns} rows={rows} />
                            <DataTablePagination />
                        </Card>
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        <Card>
                            <DataTableFilters sorts={sortingOptions} filters={filteringOptions} />
                            <DataTable dense={true} columns={tireSizeColumns} rows={tireSizeRows} />
                            <DataTablePagination />
                        </Card>
                    </CustomTabPanel>
                </CardContent>
            </Card>
        </div>
    );
}
