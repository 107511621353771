import React from "react";

export default function PricingTemplates() {
    return (
        <div>
            <h1>Pricing Templates Page</h1>
            <p>
                This page will contain a future pricing formula template feature. This will allow site managers to set
                up a templates that can quickly be applied to tires, wheels and other products quickly. Changing a
                template formula will automatically change pricing or all products associated with that template.
            </p>
        </div>
    );
}
