import React, { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CustomTabPanel from "./CustomTabPanel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddRuleDialog = ({ open, type, onClose }) => {
    const [tabValue, setTabValue] = useState(0);
    const [rules, setRules] = useState({
        basePrice: "",
        addSub1: "",
        adjustmentPrecentage: "",
        adjustmentType: "",
        addSub2: "",
        flatAdjustment: "",
        startDate: "",
        endDate: "",
        season: "",
    });
    const [selectedProducts, setSelectedProducts] = useState([]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setRules({
            ...rules,
            [name]: value,
        });
    };

    const handleRuleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const clearRules = () => {
        setRules({
            basePrice: "",
            addSub1: "",
            adjustmentPrecentage: "",
            adjustmentType: "",
            addSub2: "",
            flatAdjustment: "",
            startDate: "",
            endDate: "",
            season: "",
        });
    };

    const handleDates = (date, name) => {
        setRules({
            ...rules,
            [name]: date,
        });
    };

    const tireTypes = ["Any", "Winter", "Spring", "All Seasons"];

    const handleSelectedProductsChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedProducts(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth={"lg"}
            PaperProps={{
                component: "form",
                onSubmit: (event) => {
                    event.preventDefault();
                    clearRules();
                    onClose();
                },
            }}
        >
            <DialogTitle>Add {type} Rule</DialogTitle>
            <DialogContent>
                <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
                    <FormControl sx={{ width: 250 }} size="small">
                        <InputLabel id="demo-simple-select-label">Base Price Selection</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="basePrice"
                            value={rules.basePrice}
                            label="Base Price Selection"
                            onChange={handleFormChange}
                        >
                            <MenuItem value={"Cost"}>Cost</MenuItem>
                            <MenuItem value={"MSRP"}>MSRP</MenuItem>
                            <MenuItem value={"Price"}>Price</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 130 }} size="small">
                        <InputLabel id="demo-simple-select-label">Plus/Minus</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="addSub1"
                            value={rules.addSub1}
                            label="Plus/Minus"
                            onChange={handleFormChange}
                        >
                            <MenuItem value={"+"}>+</MenuItem>
                            <MenuItem value={"-"}>-</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 250 }} size="small">
                        <TextField
                            id="outlined-basic"
                            label="Adjustment (%)"
                            size="small"
                            variant="outlined"
                            placeholder="0%"
                            name="adjustmentPrecentage"
                            value={rules.adjustmentPrecentage}
                            onChange={handleFormChange}
                        />
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="adjustmentType"
                            value={rules.adjustmentType}
                            onChange={handleFormChange}
                        >
                            <FormControlLabel value="markup" control={<Radio />} label="Markup" />
                            <FormControlLabel value="gross margin" control={<Radio />} label="Gross Margin" />
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={{ width: 130 }} size="small">
                        <InputLabel id="demo-simple-select-label">Plus/Minus</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name={"addSub2"}
                            value={rules.addSub2}
                            label="Plus/Minus"
                            onChange={handleFormChange}
                        >
                            <MenuItem value={"+"}>+</MenuItem>
                            <MenuItem value={"-"}>-</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        id="outlined-basic"
                        label="Flat Adjustment"
                        size="small"
                        variant="outlined"
                        placeholder="$0.00"
                        name={"flatAdjustment"}
                        value={rules.flatAdjustment}
                        onChange={handleFormChange}
                    />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <DatePicker
                        format="D MMM, YYYY"
                        label="Start date"
                        value={dayjs(rules.startDate)}
                        onChange={(d) => handleDates(d, "startDate")}
                        slotProps={{
                            textField: {
                                width: 250,
                                size: "small",
                            },
                        }}
                    />
                    <DatePicker
                        format="D MMM, YYYY"
                        label="End date"
                        value={dayjs(rules.endDate)}
                        onChange={(d) => handleDates(d, "endDate")}
                        slotProps={{
                            textField: {
                                width: 250,
                                size: "small",
                            },
                        }}
                    />
                    <FormControl sx={{ width: 250 }} size="small">
                        <InputLabel id="demo-simple-select-label">Season</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="season"
                            value={rules.season}
                            label="Season"
                            onChange={handleFormChange}
                        >
                            {" "}
                            <MenuItem value={"all year"}>All year</MenuItem>
                            <MenuItem value={"winter"}>Winter</MenuItem>
                            <MenuItem value={"spring"}>Spring</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <DialogContentText sx={{ mt: 2 }}>
                    Rule: {rules.basePrice && type} {rules.basePrice} {rules.adjustmentPrecentage && rules.addSub1}{" "}
                    {rules.adjustmentPrecentage && rules.adjustmentPrecentage + "%"} {rules.adjustmentType}{" "}
                    {rules.flatAdjustment && rules.addSub2 + " a flat fee of $"}
                    {rules.flatAdjustment && rules.flatAdjustment + "."}{" "}
                    {rules.startDate && "Starting from " + dayjs(rules.startDate).format("D MMM, YYYY")}
                    {rules.endDate && " till " + dayjs(rules.endDate).format("D MMM, YYYY")}{" "}
                    {rules.season && " for " + rules.season + "."}
                </DialogContentText>

                <Divider sx={{ my: 2 }} />
                <Box>
                    <Tabs value={tabValue} onChange={handleRuleTabChange} aria-label="Rule Sections">
                        <Tab label="All Products" />
                        <Tab label="Brands" />
                        <Tab label="Models" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <FormControl sx={{ width: 300 }}>
                        <InputLabel id="tire-type-checkbox-label">Tire Type</InputLabel>
                        <Select
                            labelId="tire-type-checkbox-label"
                            id="tire-type-checkbox"
                            multiple
                            value={selectedProducts}
                            onChange={handleSelectedProductsChange}
                            input={<OutlinedInput label="Tire Type" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                        >
                            {tireTypes.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={selectedProducts.includes(name)} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CustomTabPanel>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose();
                        clearRules();
                    }}
                >
                    Cancel
                </Button>
                <Button type="submit" variant="contained">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default AddRuleDialog;
