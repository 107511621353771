import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    InputBase,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import settings from "../../../settings/api";
import { getSubdomain } from "../../../Helpers/getSubdomain";

const TextFieldContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(3, 0),
    width: "60%",
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        // backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 16,
        // width: "auto",
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

const NewGroupType = ({ mode, ...props }) => {
    const navigate = useNavigate();
    let { state } = useLocation();
    const [showDelete, setShowDelete] = useState(false);
    // console.log(location);
    const [groupType, setGroupType] = useState({
        name: "",
        description: "",
    });

    const handleNewGroupTypeInfoChange = (e) => {
        const { name, value } = e.target;
        setGroupType({
            ...groupType,
            [name]: value,
        });
    };

    useEffect(() => {
        const subdomain = getSubdomain();
        if (mode === "edit") {
            const url =
                settings.api().organizations +
                `/${JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId}/group-types/${
                    state.groupType
                }`;
            // console.log(url);
            fetch(url, {
                headers: {
                    "X-Tenant": subdomain,
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    // console.log(res);
                    // if (res.status === 200) {
                    //     // navigate(
                    //     //     `/admin/organizations/group-types?organizationId=${
                    //     //         JSON.parse(localStorage.getItem("organization"))?.id
                    //     //     }`
                    //     // );
                    // }
                    return res.json();
                })
                .then((data) => {
                    // console.log(data);
                    setGroupType(data);
                })
                .catch((error) => console.error(error));
        }

        return () => {
            // second;
        };
    }, []);

    const handleCreateNewGroupType = () => {
        // console.log(groupType);
        const subdomain = getSubdomain();
        const url =
            settings.api().organizations +
            `/${JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId}/group-types`;
        fetch(url, {
            method: "POST",
            headers: {
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(groupType),
        })
            .then((res) => {
                console.log(res);
                if (res.status === 201) {
                    navigate(
                        `/admin/organizations/group-types?organizationId=${
                            JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId
                        }`
                    );
                }
                // return res.json();
            })
            .catch((error) => console.error(error));
    };
    const handleUpdateGroupType = () => {
        // console.log(groupType);
        const subdomain = getSubdomain();
        const url =
            settings.api(JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId)
                .organizationGroupTypes + `/${groupType.id}`;
        fetch(url, {
            method: "PUT",
            headers: {
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: groupType.name, description: groupType.description }),
        })
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    navigate(
                        `/admin/organizations/${
                            JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId
                        }/group-types`
                    );
                }
                // return res.json();
            })
            .catch((error) => console.error(error));
    };

    const handleDeleteGroupType = () => {
        const subdomain = getSubdomain();
        const url =
            settings.api(JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId)
                .organizationGroupTypes + `/${groupType.id}`;
        fetch(url, {
            method: "DELETE",
            headers: {
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((res) => {
                // console.log(res);
                if (res.status === 200) {
                    navigate(
                        `/admin/organizations/${
                            JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId
                        }/group-types`
                    );
                }
            })
            .catch((error) => console.error(error));
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                {mode === "create" ? "Create New Group Type" : "Edit Group Type"}
            </Typography>
            <Typography variant="subtitle1">New Group Information</Typography>
            <Divider />
            <Box component="form">
                <TextFieldContainer>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Name
                        </InputLabel>
                        <BootstrapInput
                            required={true} // not working
                            name={"name"}
                            value={groupType.name}
                            onChange={(e) => handleNewGroupTypeInfoChange(e)}
                        />
                    </FormControl>
                </TextFieldContainer>
                <TextFieldContainer>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Description
                        </InputLabel>
                        <BootstrapInput
                            multiline={true}
                            minRows={3}
                            name={"description"}
                            value={groupType.description}
                            onChange={(e) => handleNewGroupTypeInfoChange(e)}
                        />
                    </FormControl>
                </TextFieldContainer>
            </Box>
            <Divider />
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 2, mb: 1 }}>
                <div>
                    <div
                    // style={{ marginTop: "10px" }}
                    >
                        {mode === "create" ? (
                            <Button
                                variant="contained"
                                style={{ marginRight: "10px" }}
                                onClick={handleCreateNewGroupType}
                            >
                                Create
                            </Button>
                        ) : (
                            <Button variant="contained" style={{ marginRight: "10px" }} onClick={handleUpdateGroupType}>
                                Update
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            component={Link}
                            to={`/admin/organizations/${
                                JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId
                            }/group-types`}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                {mode === "edit" && (
                    <div>
                        <Button variant="outlined" onClick={() => setShowDelete(true)} color="error">
                            Delete
                        </Button>
                    </div>
                )}
            </Stack>
            <Dialog
                open={showDelete}
                onClose={() => setShowDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this group type?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setShowDelete(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="error" onClick={handleDeleteGroupType}>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NewGroupType;
