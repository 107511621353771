import React from "react";

export default function TPOBookings() {
    return (
        <div>
            <h1>TPO Bookings</h1>
            <p>
                Will contain a list of bookings for all organizations that the Global Admin has access to. Booking CSV
                files can be downloaded from this page directly without needing the dealers to email. In future versions
                we can add email notifications for when ne data becomes available and eventually integration bookings
                into target API's to remove the need for CSV files altogether.
            </p>
        </div>
    );
}
