import React, { useEffect, useState } from "react";
import {
    Button,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
} from "@mui/material";
// import settings from "../../../settings/api";
// import fetchData from "../../../Helpers/apiCalls";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import LanguageIcon from "@mui/icons-material/Language";
import fetchData from "../Helpers/apiCalls";
import settings from "../settings/api";
import { SettingsDrawer } from "./TranslationDrawer/TranslationDrawer";
import DeleteIcon from '@mui/icons-material/Delete';
// import { SettingsDrawer } from "../../../components/TranslationDrawer/TranslationDrawer";

export default function AddEditTPOScheduleForm({ mode }) {
    const navigate = useNavigate();
    const [organizations, setOrganizations] = useState([]);
    const [products, setProducts] = useState([]);
    const [types, setTypes] = useState([]);
    const [createScheduleInfo, setCreateScheduleInfo] = useState({
        title: "",
        description: "",
        startDate: null,
        endDate: null,
        organizationId: "",
        productId: "",
        scheduleTypeId: "",
    });
    const [dataError, setDataError] = useState({
        startDateError: false,
        endDateError: false,
        titleError: false,
        descriptionError: false,
        organizationIdError: false,
        productIdError: false,
        scheduleTypeIdError: false,
    });
    const [openTranslationDrawer, setOpenTranslationDrawer] = useState(false);
    const [translationField, setTranslationField] = useState({});

    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    useEffect(() => {
        const fetchProductsNTypes = async () => {
            const organizationResult = await fetchData(settings.api().tpoScheduleOrganizations, "GET");

            if (!organizationResult.error) {
                setOrganizations(organizationResult.data);
            }

            const productResult = await fetchData(settings.api().tpoScheduleProducts, "GET");
            if (!productResult.error) {
                setProducts(productResult.data);
            }

            const seasonsResult = await fetchData(settings.api().tpoScheduleTypes, "GET");
            if (!seasonsResult.error) {
                setTypes(seasonsResult.data);
            }
        };

        fetchProductsNTypes();

        return () => {};
    }, []);

    const handleCreateScheduleInfoChange = (e) => {
        // console.log(e);

        const { name, value } = e.target;
        setCreateScheduleInfo({
            ...createScheduleInfo,
            [name]: value,
        });
    };
    const handleCreateScheduleDateChange = (date, name) => {
        setCreateScheduleInfo({
            ...createScheduleInfo,
            [name]: date?.toDate(),
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        let errors = dataError;
        errors = Object.entries(createScheduleInfo).reduce((acc, [key, value]) => {
            acc[`${key}Error`] = value === "" || value === null;
            return acc;
        }, {});

        setDataError(errors);

        const CreateSchedule = async () => {
            // console.log(JSON.stringify(createScheduleInfo));
            const result = await fetchData(settings.api().tpoSchedules, "POST", createScheduleInfo);
            // console.log(result);

            if (!result.error) {
                navigate("/admin/global/tire-quote/tpo/schedules");
            }
            // console.log(result);
        };
        const hasErrors = Object.values(errors).some((value) => value === true);
        // console.log(hasErrors);
        if (!hasErrors) {
            CreateSchedule();
        }
    };

    return (
        <div>
            {/* <h1>Add TPO Schedule</h1> */}
            <Stack>
                {/* <form onSubmit={(e) => handleSubmit(e)}> */}
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <FormControl variant="outlined" fullWidth required error={dataError.titleError}>
                            <InputLabel htmlFor="outlined-adornment-title">Title</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-title"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={"add translation"}
                                            onClick={() => setOpenTranslationDrawer(true)}
                                            edge="end"
                                            // color="primary"
                                        >
                                            <LanguageIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Title"
                                name="title"
                                value={createScheduleInfo.title}
                                onChange={handleCreateScheduleInfoChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl variant="outlined" fullWidth required error={dataError.descriptionError}>
                            <InputLabel htmlFor="outlined-adornment-description">Description</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-description"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={"add translation"}
                                            onClick={() => setOpenTranslationDrawer(true)}
                                            edge="end"
                                            variant="contained"
                                        >
                                            <LanguageIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Description"
                                name="description"
                                value={createScheduleInfo.description}
                                onChange={handleCreateScheduleInfoChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <DatePicker
                            format="D MMM, YYYY"
                            label="Start date"
                            value={dayjs(createScheduleInfo.startDate)}
                            onChange={(d) => handleCreateScheduleDateChange(d, "startDate")}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    error: dataError.startDateError,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <DatePicker
                            format="D MMM, YYYY"
                            label="End date"
                            value={dayjs(createScheduleInfo.endDate)}
                            onChange={(d) => handleCreateScheduleDateChange(d, "endDate")}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    required: true,
                                    error: dataError.endDateError,
                                },
                            }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth required error={dataError.organizationIdError}>
                            <InputLabel id="demo-simple-select-label">Organizations</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={createScheduleInfo.organizationId}
                                label="Organizations"
                                name="organizationId"
                                onChange={(e) => handleCreateScheduleInfoChange(e)}
                            >
                                {organizations.length > 0 &&
                                    organizations.map((o) => (
                                        <MenuItem key={o.id} value={o.id}>
                                            {o.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth required error={dataError.productIdError}>
                            <InputLabel id="demo-simple-select-label">Products</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={createScheduleInfo.productId}
                                label="Products"
                                name="productId"
                                onChange={(e) => handleCreateScheduleInfoChange(e)}
                            >
                                {products.length > 0 &&
                                    products.map((p) => (
                                        <MenuItem key={p.id} value={p.id}>
                                            {p.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth required error={dataError.scheduleTypeIdError}>
                            <InputLabel id="demo-simple-select-label">Season</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={createScheduleInfo.scheduleTypeId}
                                name="scheduleTypeId"
                                label="Season"
                                onChange={(e) => handleCreateScheduleInfoChange(e)}
                            >
                                {types.length > 0 &&
                                    types.map((t) => (
                                        <MenuItem key={t.id} value={t.id}>
                                            {t.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <CardActions sx={{ justifyContent: "flex-end" }}>
                    <Button color="secondary" onClick={() => navigate("/admin/global/tpo/schedules")}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" onClick={handleSubmit}>
                        {mode === "create" ? "Create Schedule" : "Modify Schedule"}
                    </Button>
                </CardActions>
                {/* </form> */}
                <div>
                    {mode === "edit" && (
                        <Button variant="contained"  startIcon={<DeleteIcon />}onClick={() => setOpenConfirmDelete(true)}>
                            Delete Schedule
                        </Button>
                    )}
                </div>
            </Stack>
            <Dialog
                open={openConfirmDelete}
                onClose={() => setOpenConfirmDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" color="error">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this schedule?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDelete(false)}>Cancel</Button>
                    <Button onClick={() => setOpenConfirmDelete(false)} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <SettingsDrawer
                onClose={() => {
                    setOpenTranslationDrawer(false);
                }}
                open={openTranslationDrawer}
                field={translationField}
                // onReset={handleReset}
                // onUpdate={handleUpdate}
                // values={settings}
            />
        </div>
    );
}
