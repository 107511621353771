import React, { useState } from "react";
import { Avatar, Box, Card, CardContent, CardHeader, Tab, Tabs, Typography } from "@mui/material";
import { Calendar as CalendarIcon } from "@phosphor-icons/react";
import CustomTabPanel from "../../../../components/CustomTabPanel";
import Activity from "./Activity";
import AddEditTPOSchedule from "../AddEditTPOSchedule";
import AddEditTPOScheduleForm from "../../../../components/AddEditTPOScheduleForm";

const TPOSchedule = () => {
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar>
                            <CalendarIcon />
                        </Avatar>
                    }
                    title={<Typography variant="h6">Title</Typography>}
                />
                <CardContent>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="featured category tabs">
                            <Tab label="Bookings" />
                            <Tab label="Edit" />
                            <Tab label="Activity" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}></CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        <AddEditTPOScheduleForm mode={"edit"} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={2}>
                        <Activity />
                    </CustomTabPanel>
                </CardContent>
            </Card>
        </div>
    );
};

export default TPOSchedule;
