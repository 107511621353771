const settings = {
    dev_env: {
        env: "PROD", // PROD or DEV
        api_host_dev: "http://localhost:8080",
        api_host_prod: "https://services.sapherasaas.com",
        api_path: "/api/v1/",
        api_request_header_pagination: "x-pagination",
    },
    getSubdomain() {
        const hostname = window.location.hostname;
        const subdomain = hostname.split(".")[0];
        return subdomain;
    },
    api(value = undefined) {
        // Set environment variables
        var host = "";
        if (this.dev_env.env === "DEV") {
            host = this.dev_env.api_host_dev;
        } else {
            host = this.dev_env.api_host_prod;
        }

        const route = host + this.dev_env.api_path;

        return {
            host: host,
            domains: route + "public/domains",
            portalInfo: route + "public/portal-info",
            authenticate: route + "authenticate",
            checkToken: route + "check-token",
            refreshToken: route + "refresh-token",
            activity: route + "activity/saas",
            // standard
            meSite: route + "me/site",
            users: route + "me/site/users",
            vehicleYear: route + "vehicles/years",
            meActivity: route + "me/site/activity",
            account: route + "account",

            // site Admin
            groups: route + "admin/groups",
            organizations: route + "admin/organizations",
            organizationUsers: route + `admin/organizations/${value}/users`,
            organizationSites: route + `admin/organizations/${value}/sites`,
            organizationGroupTypes: route + `admin/organizations/${value}/group-types`,
            siteTpoSchedules: route + `admin/sites/${value}/tire-quote/tpo/schedules`,

            // portal Admin
            portalAdminUsers: route + `admin/organizations/${value}/users`,

            // global 
            globalOrganizations: route + "admin/global/organizations",
            tpoSchedules: route + "admin/global/tire-quote/tpo/schedules",
            tpoScheduleOrganizations: route + "admin/global/tire-quote/tpo/schedules/organizations",
            tpoScheduleProducts: route + "admin/global/tire-quote/tpo/schedules/products",
            tpoScheduleTypes: route + "admin/global/tire-quote/tpo/schedules/types", 
            tpoScheduleActivity: route + `admin/global/tire-quote/tpo/schedules/${value}/activity`,

            // settings
            standardMenu: route + "system/menus/standard",
            structure: route + "system/structure",
            siteAdminMenu: route + "system/structure/site",
            portalAdminMenu: route + "system/structure/organization",
            systemAdminMenu: route + "system/structure/global",
            content: route + "system/translations/content",
            timezones: route + "system/timezones",
            updateLanguage: route + "update-language",
            checkLanguage: route + "check-language",
            translations: route + "translations",
            
            versions: route + "system/versions",
        };
    },
};
export default settings;
