import React from "react";

export default function TireQuoteAccessories() {
    return (
        <div>
            <h1>Accessory Data Page</h1>
            <p>
                This page will contain all the accessories that are visible to the site manager. This data will be
                different from the accessories list on the User view since here the accessories will contain additional
                information not visible to standard users and allow for actions such as price modifications and reports
                on a accessory-by-accessory basis.
            </p>
        </div>
    );
}
