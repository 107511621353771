import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonBase,
    Card,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    styled,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import { useTranslation } from "react-i18next";

function GridItem(props) {
    const { children, xs } = props;

    return (
        <Grid
            item
            xs={xs}
            style={{ textAlign: "center", border: "1px solid lightgrey", padding: "5px", height: "100px" }}
        >
            {children}
        </Grid>
    );
}

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
        width: "100% !important", // Overrides inline-style
        height: "40px",
    },
    "&:hover, &.Mui-focusVisible": {
        zIndex: 1,
        border: "2px solid grey",
        "& .MuiImageBackdrop-root": {
            opacity: 0.15,
        },
        "& .MuiImageMarked-root": {
            opacity: 0,
        },
    },
}));

const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
});

const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
}));

const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
}));
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function TireQuote() {
    const domain = getDomain();
    const subdomain = getSubdomain();
    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const [expanded, setExpanded] = useState(true);

    const handleAccordionToggle = (event, isExpanded) => {
        setExpanded(isExpanded ? true : false);
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const [selectedValues, setSelectedValues] = useState({
        year: "",
        make: "",
        model: "",
        trim: "",
        option: "",
    });
    const [years, setYears] = useState([]);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [trims, setTrims] = useState([]);
    const [options, setOptions] = useState([]);

    const fetchData = (url, method, setState) => {
        fetch(url, {
            method,
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error("Error fetching data");
                }
            })
            .then((data) => {
                console.log(data);
                setState(data);
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        fetchData(settings.api().vehicleYear, "GET", setYears);
    }, []);

    const handleSelect = (field, value, linkRel, setState) => {
        const resetFields = {
            year: { make: "", model: "", trim: "", option: "" },
            make: { model: "", trim: "", option: "" },
            model: { trim: "", option: "" },
            trim: { option: "" },
        };

        setSelectedValues((prevValues) => ({
            ...prevValues,
            [field]: value,
            ...resetFields[field],
        }));

        const link = value?.links.find((link) => link.rel === linkRel);
        if (link) {
            fetchData(settings.api().host + link.href, link.method, setState);
        }
    };

    const handleSelectYear = (e) => handleSelect("year", e.target.value, "vehicleMakes", setMakes);
    const handleSelectMake = (e) => handleSelect("make", e.target.value, "vehicleModels", setModels);
    const handleSelectModel = (e) => handleSelect("model", e.target.value, "vehicleTrims", setTrims);
    const handleSelectTrim = (e) => handleSelect("trim", e.target.value, "vehicleOptions", setOptions);

    return (
        <Accordion
            expanded={expanded}
            elevation={expanded ? 0 : 1}
            onChange={(e, expanded) => handleAccordionToggle(e, expanded)}
            sx={{ mb: 2, width: "100%", p: 0 }}
        >
            <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
                {expanded ? (
                    // <Typography variant="h6">Tire Quote</Typography>
                    <Typography variant="h5">Tire Quote</Typography>
                ) : (
                    // <Typography variant="h6">Modify Tire Search</Typography>
                    <Typography variant="h5">Modify Tire Search</Typography>
                )}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                <Card>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab
                                label={
                                    <>
                                        <Typography style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                                            VEHICLE
                                        </Typography>
                                        <Typography variant="caption">(Year/Make/Model/VIN)</Typography>
                                    </>
                                }
                                {...a11yProps(0)}
                            />
                            <Tab label="Size" {...a11yProps(1)} />
                            <Tab label="SKU/PART No." {...a11yProps(2)} />
                            <Tab label="PRODUCT" {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Typography gutterBottom sx={{ fontSize: "18px" }}>
                            Search Vehicle
                        </Typography>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                            <FormControl sx={{ flexGrow: 1, minWidth: "150px" }}>
                                <InputLabel id="demo-simple-select-label">
                                    {t("tireService.vehicle_year", "Year")}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValues.year}
                                    label="Year"
                                    onChange={(e) => handleSelectYear(e)}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {years.length > 0 &&
                                        years.map((y) => (
                                            <MenuItem key={y.year} value={y}>
                                                {y.year}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                sx={{ flexGrow: 1, minWidth: "150px" }}
                                disabled={selectedValues.year === null}
                            >
                                <InputLabel id="demo-simple-select-label">
                                    {t("tireService.vehicle_make", "Make")}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValues.make}
                                    label="Make"
                                    onChange={(e) => handleSelectMake(e)}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {makes.length > 0 &&
                                        makes.map((m) => (
                                            <MenuItem key={m.makeId} value={m}>
                                                {m.makeName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                sx={{ flexGrow: 1, minWidth: "150px" }}
                                disabled={selectedValues.make === null}
                            >
                                <InputLabel id="demo-simple-select-label">
                                    {t("tireService.vehicle_model", "Model")}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValues.model}
                                    label="Model"
                                    onChange={(e) => handleSelectModel(e)}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {models.length > 0 &&
                                        models.map((m) => (
                                            <MenuItem key={m.modelId} value={m}>
                                                {m.modelName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                sx={{ flexGrow: 1, minWidth: "150px" }}
                                disabled={selectedValues.model === null}
                            >
                                <InputLabel id="demo-simple-select-label">
                                    {t("tireService.vehicle_trim", "Trim")}
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValues.trim}
                                    label="Trim"
                                    onChange={(e) => handleSelectTrim(e)}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {trims.length > 0 &&
                                        trims.map((t) => (
                                            <MenuItem key={t.trimId} value={t}>
                                                {t.trimName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <FormControl
                                sx={{ flexGrow: 1, minWidth: "150px" }}
                                disabled={selectedValues.trim === null}
                            >
                                <InputLabel id="demo-simple-select-label">Option</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedValues.option}
                                    label="Option"
                                    // onChange={handleChange}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                maxHeight: 300,
                                            },
                                        },
                                    }}
                                >
                                    {options.length > 0 &&
                                        options.map((t) => (
                                            <MenuItem key={t.trimId} value={t}>
                                                {t.trimName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ minWidth: "200px" }}>
                            <div style={{ margin: "10px 0 5px" }}>
                                <Typography variant="body2" color="textSecondary">
                                    Recent searches
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexWrap: "wrap", // Allows text wrapping
                                        alignItems: "center",
                                    }}
                                >
                                    <Link>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            sx={{ textDecoration: "underline", mr: 2 }}
                                        >
                                            235/65R18
                                        </Typography>
                                    </Link>
                                    <Link>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            sx={{ textDecoration: "underline", mr: 2 }}
                                        >
                                            235/65R18
                                        </Typography>
                                    </Link>
                                    <Link>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            sx={{ textDecoration: "underline", mr: 2 }}
                                        >
                                            235/65R18
                                        </Typography>
                                    </Link>
                                    <Link>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            sx={{ textDecoration: "underline", mr: 2 }}
                                        >
                                            235/65R18
                                        </Typography>
                                    </Link>
                                    <Link>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            sx={{ textDecoration: "underline", mr: 2 }}
                                        >
                                            235/65R18
                                        </Typography>
                                    </Link>
                                    <Link>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            sx={{ textDecoration: "underline", mr: 2 }}
                                        >
                                            235/65R18
                                        </Typography>
                                    </Link>
                                </Box>
                            </div>
                            <Box>
                                <Button variant="outlined" sx={{ mr: 2 }}>
                                    Clear
                                </Button>
                                <Button variant="contained">Search</Button>
                            </Box>
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Typography gutterBottom sx={{ fontSize: "18px" }}>
                            Search by size
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            <TextField id="outlined-basic" label="Size" variant="outlined" size="small" />
                            <ClearIcon />
                            <TextField id="outlined-basic" label="Qty" variant="outlined" size="small" />
                        </Box>
                        <Button startIcon={<AddIcon />} variant="outlined" sx={{ mt: 2 }}>
                            Add Row
                        </Button>

                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <div style={{ margin: "10px 0 0" }}>
                                    <Typography variant="body2" color="textSecondary">
                                        Recent searches
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap", // Allows the text to wrap when it reaches the end of the parent
                                            alignItems: "center", // Optional, if you want them vertically aligned in the center
                                        }}
                                    >
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" sx={{ mt: 2 }}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Typography gutterBottom sx={{ fontSize: "18px" }}>
                            Search SKU/Part #
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            <TextField id="outlined-basic" label="SKU or Part #" variant="outlined" size="small" />
                            <ClearIcon />
                            <TextField id="outlined-basic" label="Qty" variant="outlined" size="small" />
                        </Box>
                        <Button startIcon={<AddIcon />} variant="outlined" sx={{ mt: 2 }}>
                            Add Row
                        </Button>

                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <div style={{ margin: "10px 0 0" }}>
                                    <Typography variant="body2" color="textSecondary">
                                        Recent searches
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap", // Allows the text to wrap when it reaches the end of the parent
                                            alignItems: "center", // Optional, if you want them vertically aligned in the center
                                        }}
                                    >
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" sx={{ mt: 2 }}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography
                                    // color="textSecondary"
                                    gutterBottom
                                    sx={{ fontSize: "18px" }}
                                >
                                    Search Tires
                                </Typography>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Size"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: "120px" }}
                                    />
                                    <ClearIcon />
                                    <TextField
                                        id="outlined-basic"
                                        label="Qty"
                                        variant="outlined"
                                        size="small"
                                        style={{ width: "60px" }}
                                    />
                                </Box>
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Staggered" />
                                <div style={{ margin: "10px 0 15px" }}>
                                    <Typography variant="body2" color="textSecondary">
                                        Recent searches
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap", // Allows the text to wrap when it reaches the end of the parent
                                            alignItems: "center", // Optional, if you want them vertically aligned in the center
                                        }}
                                    >
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                        <Link>
                                            <Typography
                                                variant="subtitle2"
                                                color="textSecondary"
                                                sx={{ textDecoration: "underline", mr: 2 }}
                                            >
                                                235/65R18
                                            </Typography>
                                        </Link>
                                    </Box>
                                </div>
                                <div>
                                    <Button variant="outlined" sx={{ mr: 2 }}>
                                        Clear
                                    </Button>
                                    <Button variant="contained" style={{ marginRight: "10px" }} sx={{ flexGrow: 1 }}>
                                        Search
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={9} style={{ marginTop: "10px" }}>
                                <Grid container spacing={2} justifyContent="center" alignItems="stretch">
                                    <GridItem xs={3}>
                                        <ImageButton>
                                            <ImageSrc
                                                style={{ backgroundImage: `url(/images/mock/car-battery.jpg)` }}
                                            />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Batteries</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <ImageButton>
                                            <ImageSrc style={{ backgroundImage: `url(/images/mock/fluids.jpg)` }} />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Chemicals, Fluids & Lube</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <ImageButton>
                                            <ImageSrc style={{ backgroundImage: `url(/images/mock/tire-stack.jpg)` }} />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Marketing Materials</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <ImageButton>
                                            <ImageSrc style={{ backgroundImage: `url(/images/mock/oil.jpg)` }} />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Oil</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <ImageButton>
                                            <ImageSrc style={{ backgroundImage: `url(/images/mock/car-door.jpeg)` }} />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Parts Exterior</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <ImageButton>
                                            <ImageSrc
                                                style={{ backgroundImage: `url(/images/mock/car-console.jpg)` }}
                                            />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Program Accessories</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <ImageButton>
                                            <ImageSrc style={{ backgroundImage: `url(/images/mock/storage.jpg)` }} />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Storage & Racking</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <ImageButton>
                                            <ImageSrc
                                                style={{ backgroundImage: `url(/images/mock/tire-assembly.jpg)` }}
                                            />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">
                                                    Tire Assemblies, Packs & Kits
                                                </Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={true}>
                                        <ImageButton>
                                            <ImageSrc style={{ backgroundImage: `url(/images/mock/wheels.jpg)` }} />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Wheels</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                    <GridItem xs={true}>
                                        <ImageButton>
                                            <ImageSrc
                                                style={{
                                                    backgroundImage: `url(/images/mock/windshield-wipers.jpg)`,
                                                }}
                                            />
                                            <ImageBackdrop className="MuiImageBackdrop-root" />
                                            <Image>
                                                <Typography variant="subtitle1">Wipes</Typography>
                                            </Image>
                                        </ImageButton>
                                    </GridItem>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CustomTabPanel>
                </Card>
            </AccordionDetails>
        </Accordion>
    );
}
