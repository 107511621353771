import React, { useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    // TextField,
    Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useEffect } from "react";
import settings from "../../settings/api";
import { Link, useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";
import CustomTabPanel from "../../components/CustomTabPanel";
import generateRandomPassword from "../../Helpers/RandomPasswordGenerator";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";

const TextFieldContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(0, 0, 0),
    // width: "100%",
    // maxWidth: "250px",
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        // backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 16,
        // width: "auto",
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

const BootstrapSelect = styled(Select)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));

const ViewUser = ({ mode }, ...props) => {
    const domain = getDomain();
    const subdomain = getSubdomain();
    const location = useLocation();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        login: "",
        password: "",
        confirmPassword: "",
        phoneMobile: "",
        phoneHome: "",
        phoneWork: "",
        phoneFax: "",
        postionName: "",
        profilePublic: false,
        siteId: 0,
        organizationId: "",
        localeId: 0,
        timeZoneId: 0,
    });
    const [organizationInfo, setOrganizationInfo] = useState({});
    const [locales, setLocales] = useState([]);
    const [sites, setSites] = useState([]);
    // const [userId] = useState(location.state.user.id);
    // console.log(userId);
    // const [organizationId] = useState(location.state.user.organization.id);
    const [organizationId] = useState(JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const urlParts = location.pathname.split("/");
    const [userId] = useState(urlParts[urlParts.length - 1]);
    const [timezones, setTimezones] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // console.log(userId, organizationId, location.state.user.id);

    useEffect(() => {
        setUserInfo({ ...userInfo, organization: JSON.parse(localStorage.getItem("userOrganizationInfo")) });
        setLocales(JSON.parse(localStorage.getItem("userOrganizationInfo")).locales);
        setOrganizationInfo({ ...JSON.parse(localStorage.getItem("userOrganizationInfo")) });

        fetch(settings.api(organizationId).organizationSites, {
            method: "GET",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setSites(data);
            });

        fetch(settings.api().organizations + `/${organizationId}/users/${userId}`, {
            method: "GET",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setUserInfo({
                    ...data,
                    localeId: data.locale.id,
                    timeZoneId: data.timezone.id,
                    siteId: data.site.id,
                    organizationId: data.organization.id,
                });
            });
        fetchTimeZones();
        return () => {};
    }, []);

    const handleUserInfoChange = (e) => {
        setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    };
    // console.log(newGroup);

    const updateUser = () => {
        // console.log(settings.api(organizationId).organizationUsers, JSON.stringify(userInfo));
        fetch(settings.api(organizationId).organizationUsers + `/${userId}`, {
            method: "PUT",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userInfo),
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    navigate(`/admin/organizations/${organizationId}/users`);
                }
                // return res.json();
            })
            // .then((data) => console.log(data))
            .catch((error) => console.error(error));
    };

    const deleteUser = () => {
        fetch(settings.api(organizationId).organizationUsers + `/${userId}`, {
            method: "DELETE",
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    navigate(`/admin/organizations/${organizationId}/users`);
                }
            })
            .catch((err) => console.error(err));
    };
    const fetchTimeZones = () => {
        fetch(settings.api().timezones, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        })
            .then((res) => {
                // console.log(res);
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                setTimezones(data);
            })
            .catch((error) => console.error(error));
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                View User
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab
                        label="User"
                        // label={`${pageContent?.find((c) => c.contentId === 4)?.translation}`}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label="Enrollments"
                        // label={`${pageContent?.find((c) => c.contentId === 5)?.translation}`}
                        {...a11yProps(1)}
                    />
                    <Tab
                        label="Permissions"
                        // label={`${pageContent?.find((c) => c.contentId === 5)?.translation}`}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>
            <Paper sx={{ mt: 3 }}>
                <CustomTabPanel value={tabValue} index={0}>
                    <Typography variant="subtitle1">
                        {mode === "create" ? "Create" : "Modify"} User Information
                    </Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            display: "grid",
                            gridTemplateColumns: { sm: "1fr 1fr" },
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                First Name
                            </InputLabel>
                            <BootstrapInput
                                name={"firstName"}
                                value={userInfo.firstName}
                                onChange={(e) => handleUserInfoChange(e)}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Last Name
                            </InputLabel>
                            <BootstrapInput
                                name={"lastName"}
                                value={userInfo.lastName}
                                onChange={(e) => handleUserInfoChange(e)}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Email
                            </InputLabel>
                            <BootstrapInput
                                name={"email"}
                                type="email"
                                onChange={(e) => handleUserInfoChange(e)}
                                value={userInfo.email}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Login
                            </InputLabel>
                            <BootstrapInput name={"login"} value={userInfo.login} />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Password
                            </InputLabel>
                            <BootstrapInput
                                name={"password"}
                                type="password"
                                onChange={(e) => handleUserInfoChange(e)}
                                value={userInfo.password}
                            />
                        </FormControl>
                        <Button
                            variant="outlined"
                            onClick={generateRandomPassword}
                            style={{ height: "43px", marginTop: "25px" }}
                        >
                            Generate New Password
                        </Button>

                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Mobile Phone
                            </InputLabel>
                            <BootstrapInput
                                name={"phoneMobile"}
                                type="tel"
                                value={userInfo.phoneMobile}
                                onChange={(e) => handleUserInfoChange(e)}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Home Phone
                            </InputLabel>
                            <BootstrapInput
                                name={"phoneHome"}
                                type="tel"
                                value={userInfo.phoneHome}
                                onChange={(e) => handleUserInfoChange(e)}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Work Phone
                            </InputLabel>
                            <BootstrapInput
                                name={"phoneWork"}
                                type="tel"
                                onChange={(e) => handleUserInfoChange(e)}
                                value={userInfo.phoneWork}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Fax
                            </InputLabel>
                            <BootstrapInput disabled type="tel" name={"phoneFax"} value={userInfo.phoneFax} />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Position
                            </InputLabel>
                            <BootstrapInput
                                name={"positionName"}
                                onChange={(e) => handleUserInfoChange(e)}
                                value={userInfo.positionName}
                            />
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Profile Public"
                            checked={userInfo?.profilePublic}
                            onChange={() =>
                                setUserInfo({
                                    ...userInfo,
                                    profilePublic: !userInfo.profilePublic,
                                })
                            }
                        />
                        <FormControl fullWidth>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                Language
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.localeId}
                                name={"localeId"}
                                onChange={handleUserInfoChange}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select Language--</em>
                                </MenuItem>
                                {locales.map((l) => (
                                    <MenuItem key={l.id} value={l.id}>
                                        {l.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                Time Zone
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.timeZoneId}
                                name={"timeZoneId"}
                                onChange={handleUserInfoChange}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select Time Zone--</em>
                                </MenuItem>
                                {timezones.map((t) => (
                                    <MenuItem key={t.id} value={t.id}>
                                        {t.title}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                        {/* <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="bootstrap-input">
                        Profile Public
                    </InputLabel>
                    <BootstrapInput disabled name={"code"} value={userInfo.parentId} />
                </FormControl> */}
                    </Box>
                    <Divider /> <Typography variant="subtitle1">User's Site Information</Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            // display: "grid",
                            // gridTemplateColumns: { sm: "1fr 1fr" },
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl fullWidth>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                Site
                            </InputLabel>
                            <BootstrapSelect value={userInfo.siteId} name={"siteId"} onChange={handleUserInfoChange}>
                                <MenuItem disabled value={0}>
                                    <em>--Select Site--</em>
                                </MenuItem>
                                {sites.length > 0 &&
                                    sites.map((s) => (
                                        <MenuItem key={s.id} value={s.id}>
                                            {s.code}- {s.name}
                                        </MenuItem>
                                    ))}
                            </BootstrapSelect>
                        </FormControl>
                    </Box>
                    <Typography variant="subtitle1">User's Organization Information</Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            display: "grid",
                            gridTemplateColumns: { sm: "1fr 1fr" },
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Name
                            </InputLabel>
                            <BootstrapInput disabled value={organizationInfo.name} />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Short Name
                            </InputLabel>
                            <BootstrapInput disabled value={organizationInfo.shortName} />
                        </FormControl>
                    </Box>
                    <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <Button variant="contained" style={{ marginRight: "10px" }} onClick={updateUser}>
                                {mode === "create" ? "Create" : "Update"}
                            </Button>
                            <Button
                                variant="outlined"
                                component={Link}
                                to={`/admin/organizations/${organizationId}/users`}
                            >
                                Cancel
                            </Button>
                        </div>
                        <Button
                            color="error"
                            component={Link}
                            onClick={() => setConfirmDelete(true)}
                            variant="outlined"
                            // to={`/admin/organizations/${organizationId}/users`}
                        >
                            Delete
                        </Button>
                    </div>
                </CustomTabPanel>
            </Paper>
            <CustomTabPanel value={tabValue} index={1}></CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}></CustomTabPanel>
            <Dialog
                open={confirmDelete}
                onClose={() => setConfirmDelete(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this site?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setConfirmDelete(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="error" onClick={deleteUser}>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default ViewUser;
