import React, { useState } from "react";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from "@mui/material";
import CustomTabPanel from "../../../components/CustomTabPanel";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";
import { ChartBar as ChartBarIcon } from "@phosphor-icons/react/dist/ssr/ChartBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReactApexChart from "react-apexcharts";

import { Tire as TireIcon } from "@phosphor-icons/react/dist/ssr/Tire";

const sortingOptions = [
    // {
    //     field: "date",
    //     label: "Date",
    //     order: ["asc", "desc"],
    // },
];

const filteringOptions = [
    // {
    //     field: "status",
    //     label: "Status",
    //     labelDescription: "Filter by Status",
    //     fieldType: "dropdown",
    //     values: ["Active"],
    // },
];

const rows = [
    {
        locationType: "Dealer",
        locationId: "LOC-34124",
        locationName: "Dealer Name",
        distributor: "Tire Link",
        stock: 20,
        address: "123 Some St.",
        city: "Oshawa",
        state: "ON",
        country: "Canada",
    },
    {
        locationType: "Dealer",
        locationId: "LOC-34124",
        locationName: "Dealer Name",
        distributor: "GT",
        stock: 31,
        address: "321 That St.",
        city: "Oshawa",
        state: "ON",
        country: "Canada",
        view: true
    },
    {
        locationType: "Dealer",
        locationId: "LOC-34124",
        stock: 16,
        locationName: "Dealer Name",
        distributor: "Tire Link",
        address: "123 Some St.",
        city: "Oshawa",
        state: "ON",
        country: "Canada",
    },
];

const columns = [
    { field: "locationType", name: "Location Type", width: "100px" },
    {
        formatter: (row) => {
            return (
                <Typography variant="body2">
                    {row.locationName}
                    <br />
                    {row.locationId}
                </Typography>
            );
        },
        name: "Location",
        width: "150px",
    },
    { field: "distributor", name: "Distributor", width: "100px" },
    { field: "stock", name: "Stock", width: "100px" },

    {
        formatter: (row) => {
            return (
                <Typography variant="body2">
                    {row.address}
                    <br />
                    {row.city}
                    {row.state && ", " + row.state}
                    {row.country && ", " + row.country}
                </Typography>
            );
        },
        name: "Location",
        width: "200px",
    },
    {
        formatter: (row) => {
            return (row.view && 
                <Box>
                    <Tooltip title="View" placement="top">
                        <IconButton>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            );
        },
        name: "Action",
        width: "100px",
        align: "center",
    },
];

export default function Stock() {
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const chartState = {
        series: [
            {
                name: "Net Profit",
                data: [186, 164, 162, 157, 146, 112, 111, 107, 72],
            },
        ],
        options: {
            chart: {
                type: "bar",
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: "25%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
            },
            xaxis: {
                categories: ["Tire A", "Tire B", "Tire C", "Tire D", "Tire E", "Tire F", "Tire G", "Tire H", "Tire I"],
            },
            yaxis: {
                title: {
                    text: "Units",
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " units";
                    },
                },
            },
        },
    };

    return (
        <Stack spacing={2}>
            <Card>
                <CardHeader
                    title={<Typography variant="h6">Top Tires Sold</Typography>}
                    avatar={
                        <Avatar>
                            <TireIcon />
                        </Avatar>
                    }
                />
                <CardContent>
                    <div id="chart">
                        <ReactApexChart
                            options={chartState.options}
                            series={chartState.series}
                            type="bar"
                            height={250}
                        />
                    </div>
                </CardContent>
            </Card>
            <Card>
                <CardHeader
                    title={<Typography variant="h6">Stock</Typography>}
                    avatar={
                        <Avatar>
                            <ChartBarIcon />
                        </Avatar>
                    }
                />
                <CardContent>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="featured category tabs">
                            <Tab label="Tire" />
                            <Tab label="Wheel" />
                            <Tab label="Package" />
                            <Tab label="Accessories" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                        <DataTableFilters sorts={sortingOptions} filters={filteringOptions} />
                        <DataTable columns={columns} rows={rows} />
                        <DataTablePagination />
                    </CustomTabPanel>
                </CardContent>
            </Card>
        </Stack>
    );
}
