import React, { useState } from "react";
import { IconButton, TextField, Box, InputAdornment, Grow, Collapse, Slide } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

const ExpandableSearchBar = () => {
    const [expanded, setExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const handleExpand = () => setExpanded(true);
    const handleCollapse = () => {
        setExpanded(false);
        setSearchValue("");
    };

    return (
        <Box display="flex" alignItems="center">
            {expanded ? (
                <Collapse orientation="horizontal" direction="left" in={expanded} unmountOnExit>
                    <TextField
                        size="small"
                        variant="outlined"
                        autoFocus
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search"
                        sx={{
                            backgroundColor: "white", // Sets the background color
                            borderRadius: 1, // Optional: adds rounded corners
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
                                },
                                "&:hover fieldset": {
                                    borderColor: "rgba(0, 0, 0, 0.87)", // Border color on hover
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#FFF", // Border color when focused
                                },
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleCollapse} size="small">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Collapse>
            ) : (
                // {!expanded &&
                <IconButton onClick={handleExpand} sx={{ color: "#fff" }}>
                    <SearchIcon />
                </IconButton>
                // }
            )}
        </Box>
    );
};

export default ExpandableSearchBar;
