import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function DataTable({ dense, columns, hideHead, hover, onClick, rows, uniqueRowId, ...props }) {
    const { t } = useTranslation();

    return (
        <Table
            {...props}
            // sx={{ overflowX: "auto", maxWidth: { md: "900px" },}}
        >
            <TableHead sx={{ ...(hideHead && { visibility: "collapse", "--TableCell-borderWidth": 0 }) }}>
                <TableRow>
                    {columns?.map((column) => (
                        <TableCell
                            key={column.name}
                            sx={{
                                width: column.width,
                                minWidth: column.width,
                                maxWidth: column.width,
                                py: dense && 0,
                                px: dense ? 1 : 1.5,
                                ...(column.align && { textAlign: column.align }),
                            }}
                        >
                            {column.hideName ? null : column.name}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows?.length > 0 ? (
                    rows?.map((row, index) => {
                        const rowId = row.id ? row.id : uniqueRowId?.(row);

                        return (
                            <TableRow hover={hover} key={rowId ?? index}>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.name}
                                        sx={{
                                            py: dense && 0,
                                            px: dense ? 1 : 1.5,
                                            ...(column.align && { textAlign: column.align }),
                                        }}
                                    >
                                        {column.formatter
                                            ? column.formatter(row, index)
                                            : column.field
                                            ? row[column.field]
                                            : null}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell colSpan={columns.length}>
                            <Typography color="text.secondary" sx={{ textAlign: "center" }} variant="body2">
                                {t("base.list_noRecords", "No records found")}
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}
