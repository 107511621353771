import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BootstrapInput from "../../components/BootstrapTextField";
import styled from "@emotion/styled";
import settings from "../../settings/api";
import { getDomain, getSubdomain } from "../../Helpers/getSubdomain";
import { Password as PasswordIcon } from "@phosphor-icons/react/dist/ssr/Password";
// import StoreIcon from "@mui/icons-material/Store";
import { UserGear as UserGearIcon } from "@phosphor-icons/react/dist/ssr/UserGear";
import dayjs from "dayjs";
import fetchData from "../../Helpers/apiCalls";

const BootstrapSelect = styled(Select)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

const Profile = () => {
    const domain = getDomain();
    const subdomain = getSubdomain();

    const [timezones, setTimezones] = useState([]);
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        login: "",
        password: "",
        phone: "",
        position: "",
        profilePublic: false,
        siteId: 0,
        organizationId: "",
        localeId: 0,
        timeZoneId: 0,
    });
    const [updatePassword, setUpdatePassword] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        userId: 0,
    });
    // const [locales, setLocales] = useState([]);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        fetchTimeZones();
        fetchUser();
        return () => {};
    }, []);

    const handleUserInfoChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const updateUser = async () => {
        const body = {
            id: userInfo.id,
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
            phone: userInfo.phone,
            localeId: userInfo.localeId,
            timeZoneId: userInfo.timeZoneId,
        };
        // console.log(body);

        const result = await fetchData(settings.api().account, "PUT", body);
        // console.log(result);
        if (result.success) {
            window.location.reload();
        }
    };

    const fetchUser = async () => {
        const result = await fetchData(settings.api().account, "GET");
        if (!result.error) {
            setUserInfo({
                ...result.data,
                localeId: result?.data?.locale?.selectedId,
                timeZoneId: result?.data?.timeZone?.selectedId,
            });
        }
    };

    // console.log(typeof userInfo.timeZoneId, userInfo.timeZoneId);

    const fetchTimeZones = async () => {
        const result = await fetchData(settings.api().timezones, "GET");
        if (!result.error) {
            setTimezones(result.data);
        }
        // fetch(settings.api().timezones, {
        //     headers: {
        //         "X-Domain": domain,
        //         "X-Tenant": subdomain,
        //         Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token}`,
        //     },
        // })
        //     .then((res) => {
        //         // console.log(res);
        //         return res.json();
        //     })
        //     .then((data) => {
        //         // console.log(data);
        //         setTimezones(data);
        //     })
        //     .catch((error) => console.error(error));
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;

        setUpdatePassword({
            ...updatePassword,
            [name]: value,
        });
    };

    const handleUpdatePasswordChange = () => {
        if (
            updatePassword.currentPassword === "" ||
            updatePassword.newPassword === "" ||
            updatePassword.confirmPassword === ""
        ) {
            setShowError(true);
            return;
        }
        if (updatePassword.newPassword !== updatePassword.confirmPassword) {
            setShowError(true);
            return;
        }
        fetch(settings.api().users + `/${userInfo.id}/change-password`, {
            headers: {
                "X-Domain": domain,
                "X-Tenant": subdomain,
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            method: "PUT",
            body: JSON.stringify(),
        }).then((res) => {
            console.log(res);
            return res.json();
        });
    };

    return (
        <div>
            <div>
                <Typography variant="h4">Account</Typography>
            </div>
            <Card sx={{ mt: 4 }}>
                <CardHeader
                    // avatar={<Avatar>Logo</Avatar>}
                    title={
                        <>
                            <Stack direction="row" spacing={2} sx={{ alignItems: "center", flex: "1 1 auto" }}>
                                <Avatar src="/assets/avatar-1.png" sx={{ width: 64, height: 64 }}>
                                    Logo
                                </Avatar>
                                <div>
                                    <Stack direction="row" spacing={2} sx={{ alignItems: "center", flexWrap: "wrap" }}>
                                        <Typography variant="h4">{userInfo?.siteName}</Typography>
                                    </Stack>
                                    <Typography color="textSecondary" variant="body1">
                                        {userInfo?.siteCode} site_code
                                    </Typography>
                                </div>
                            </Stack>
                        </>
                    }
                />
                <CardContent sx={{ pb: 0 }}>
                    <Stack
                        direction="row"
                        spacing={2}
                        divider={<Divider orientation="vertical" flexItem />}
                        sx={{
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Role
                            </Typography>
                            <Chip
                                icon={<UserGearIcon color="#15b79f" weight="fill" />}
                                label="Manager"
                                size="small"
                                variant="outlined"
                                // color="#15b79f"
                                // sx={{ backgroundColor: "#15b79f", color: "#FFF" }}
                            />
                        </Box>
                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Employee Code
                            </Typography>
                            <Typography variant="subtitle1">{userInfo?.code}12838DQ83DA</Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Department
                            </Typography>
                            <Typography variant="subtitle1">{userInfo?.department}deparment</Typography>
                        </Box>
                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Position
                            </Typography>
                            <Typography variant="subtitle1">{userInfo?.position}position</Typography>
                        </Box>

                        <Box>
                            <Typography variant="subtitle2" color="text.secondary">
                                Account Created
                            </Typography>
                            <Typography variant="subtitle1">
                                {dayjs(userInfo?.createUtc).format("MMM D, YYYY")}
                            </Typography>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
            <Card sx={{ mt: 4 }}>
                <CardHeader
                    avatar={<Avatar>{/* <StorefrontIcon fontSize="large" /> */}</Avatar>}
                    title={
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                Basic Details
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                display={"inline"}
                                sx={{ marginRight: "5px" }}
                            >
                                Last modified: {dayjs(userInfo.modifiedUtc).format("MMM DD, YYYY")}
                            </Typography>
                        </Box>
                    }
                />
                <CardContent sx={{ pb: 0 }}>
                    <Typography variant="subtitle1">My Information</Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            display: "grid",
                            gridTemplateColumns: { sm: "1fr 1fr" },
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>First Name</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"firstName"}
                                value={userInfo.firstName}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Last Name</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"lastName"}
                                value={userInfo.lastName}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Email</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"email"}
                                type="email"
                                onChange={handleUserInfoChange}
                                value={userInfo.email}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Login
                            </InputLabel>
                            <BootstrapInput name={"login"} value={userInfo.login} onChange={handleUserInfoChange} />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Phone Number
                            </InputLabel>
                            <BootstrapInput
                                name={"phone"}
                                type="tel"
                                value={userInfo.phone}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        {/* <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Home Phone
                            </InputLabel>
                            <BootstrapInput
                                name={"phoneHome"}
                                type="tel"
                                value={userInfo.phoneHome}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Work Phone
                            </InputLabel>
                            <BootstrapInput
                                name={"phoneWork"}
                                type="tel"
                                onChange={handleUserInfoChange}
                                value={userInfo.phoneWork}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Fax
                            </InputLabel>
                            <BootstrapInput
                                type="tel"
                                name={"phoneFax"}
                                value={userInfo.phoneFax}
                                onChange={handleUserInfoChange}
                            />
                        </FormControl> 
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Position</strong>
                            </InputLabel>
                            <BootstrapInput
                                name={"positionName"}
                                onChange={handleUserInfoChange}
                                value={userInfo.positionName}
                            />
                        </FormControl> */}
                        <FormControlLabel
                            control={<Checkbox />}
                            label="Profile Public"
                            checked={userInfo?.profilePublic}
                            onChange={() =>
                                setUserInfo({
                                    ...userInfo,
                                    profilePublic: !userInfo.profilePublic,
                                })
                            }
                        />
                        <FormControl
                            fullWidth
                            required={true}
                            // disabled={userInfo?.locale?.editable}
                        >
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>Language</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo?.localeId}
                                onChange={handleUserInfoChange}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 300,
                                        },
                                    },
                                }}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select Language--</em>
                                </MenuItem>
                                {userInfo?.locale?.values?.map((l) => (
                                    <MenuItem key={l.id} value={l.id}>
                                        {l.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                        <FormControl fullWidth required={true}>
                            <InputLabel variant="standard" shrink={true} htmlFor="bootstrap-input">
                                <strong>Time Zone</strong>
                            </InputLabel>
                            <BootstrapSelect
                                value={userInfo.timeZoneId}
                                name={"timeZoneId"}
                                onChange={handleUserInfoChange}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: 300,
                                        },
                                    },
                                }}
                            >
                                <MenuItem disabled value={0}>
                                    <em>--Select Time Zone--</em>
                                </MenuItem>
                                {timezones.map((t) => (
                                    <MenuItem key={t.id} value={t.id}>
                                        {t.name}
                                    </MenuItem>
                                ))}
                            </BootstrapSelect>
                        </FormControl>
                    </Box>
                    <div style={{ marginTop: "0px" }}>
                        <Button variant="outlined" style={{ marginRight: "10px" }} onClick={updateUser}>
                            Update
                        </Button>
                    </div>
                </CardContent>
            </Card>
            <Card sx={{ mt: 4 }}>
                <CardHeader
                    avatar={
                        <Avatar>
                            <PasswordIcon fontSize="large" />
                        </Avatar>
                    }
                    title={<Typography variant="h6">Account Password</Typography>}
                />
                <CardContent sx={{ pb: 0 }}>
                    <Typography variant="subtitle1">Change Password</Typography>
                    <Divider />
                    <Box
                        component="form"
                        noValidate
                        sx={{
                            display: "grid",
                            gridTemplateColumns: { sm: "1fr 1fr" },
                            gap: 2,
                            py: 2,
                        }}
                    >
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Current Password</strong>
                            </InputLabel>
                            <BootstrapInput
                                type="password"
                                name={"currentPassword"}
                                onChange={handlePasswordChange}
                                value={updatePassword.currentPassword}
                            />
                        </FormControl>
                        <div></div>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>New Password</strong>
                            </InputLabel>
                            <BootstrapInput
                                type="password"
                                name={"newPassword"}
                                onChange={handlePasswordChange}
                                value={updatePassword.newPassword}
                            />
                        </FormControl>
                        <FormControl variant="standard" fullWidth required={true}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                                <strong>Confirm Password</strong>
                            </InputLabel>
                            <BootstrapInput
                                type="password"
                                name={"confirmPassword"}
                                onChange={handlePasswordChange}
                                value={updatePassword.confirmPassword}
                            />
                        </FormControl>
                    </Box>
                    <div style={{ marginTop: "0px" }}>
                        <Button
                            variant="outlined"
                            color="error"
                            style={{ marginRight: "10px" }}
                            onClick={handleUpdatePasswordChange}
                        >
                            Update Password
                        </Button>
                    </div>
                </CardContent>
            </Card>

            <Dialog
                open={showError}
                onClose={() => setShowError(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Oops!</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please ensure all password fields are filled, and New and Confirm passwords are the same.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setShowError(false)}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Profile;
