import * as React from "react";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ArrowCounterClockwise as ArrowCounterClockwiseIcon } from "@phosphor-icons/react/dist/ssr/ArrowCounterClockwise";
import { X as XIcon } from "@phosphor-icons/react/dist/ssr/X";
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from "@mui/material";

// import { OptionsColorScheme } from './options-color-scheme';
// import { OptionsDirection } from './options-direction';
// import { OptionsLayout } from './options-layout';
// import { OptionsNavColor } from './options-nav-color';
// import { OptionsPrimaryColor } from './options-primary-color';

export function SettingsDrawer({ field, canReset = true, onClose, onUpdate, onReset, open, values = {} }) {
    const handleChange = React.useCallback(
        (field, value) => {
            onUpdate?.({ [field]: value });
        },
        [onUpdate]
    );

    return (
        <Drawer
            ModalProps={{ BackdropProps: { invisible: true }, sx: { zIndex: 1400 } }}
            PaperProps={{
                elevation: 24,
                sx: { display: "flex", flexDirection: "column", maxWidth: "100%", width: "440px", bgcolor: "#fff" },
            }}
            anchor="right"
            disableScrollLock
            // onClose={onClose}
            open={open}
        >
            <Stack
                direction="row"
                spacing={3}
                sx={{ alignItems: "center", justifyContent: "space-between", px: 3, pt: 2 }}
            >
                <Typography variant="h6">Translation</Typography>
                <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                    <IconButton onClick={onClose}>
                        <XIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack spacing={2} sx={{ overflowY: "auto", p: 3 }}>
                <Typography variant="subtitle1">Field Name</Typography>
                <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Language</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={age}
                        label="Language"
                        // onChange={handleChange}
                    >
                        <MenuItem value={10}>French</MenuItem>
                    </Select>
                </FormControl>
                <TextField size="small" label="Translation" variant="outlined" />
                <Box>
                    <Button variant="outlined" sx={{ mr: 1 }}>
                        Save
                    </Button>
                    <Button variant="outlined">Add New</Button>
                </Box>
                <Box>
                    <Typography variant="subtitle1">Translations</Typography>
                    <TextField
                    fullWidth
                        size="small"
                        label=""
                        multiline
                        rows={12}
                        defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                        disabled
                    />
                </Box>
            </Stack>
        </Drawer>
    );
}
