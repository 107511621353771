import React from "react";

export default function SitesAdminDashboard() {
    return (
        <div>
            <h1>Site Management Dashboard</h1>
            <p>
                Dashboard will contain widgets such as graphs and reports to quickly guide a site manager or admin to
                various site components. Provides an overall snapshot of the selected site. The site view can be loaded
                by a user for his/her own site or by a portal admin for any site in the organization.
            </p>
        </div>
    );
}
