import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Chip, Grid, Link, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FaxIcon from "@mui/icons-material/Fax";
import StoreIcon from "@mui/icons-material/Store";
import { useTranslation } from "react-i18next";
import formatPostalCode from "../../Helpers/PostalCodeFormatter";
import formatPhone from "../../Helpers/PhoneFormatter";

const OrganizationInfoCard = ({ orgInfo }) => {
    const { t } = useTranslation();
    const [organization, setOrganization] = useState({});

    useEffect(() => {
        setOrganization(orgInfo);

        return () => {};
    }, []);

    console.log(organization);

    return (
        <Card>
            <CardContent>
                <Grid container justifyContent="space-between" column={{ xs: 12, md: 4 }}>
                    <Grid item>
                        <Typography component="h1" variant="h4" style={{ display: "flex", alignItems: "center" }}>
                            <img
                                src={JSON.parse(localStorage.getItem("userOrganizationInfo"))?.theme?.logoSmallUri}
                                alt="Logo"
                                style={{ marginRight: "8px" }} 
                            />
                            {organization?.name ? organization?.name : "Some Organization Name"}
                        </Typography>
                    </Grid>
                    <Grid item align="center">
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                // className={classes.button}
                                sx={{ margin: 1, width: "120px" }}
                                startIcon={<EditIcon />}
                                // onClick={() => setEditSiteOpen(true)}
                                component={Link}
                                to="/portal/site/edit"
                            >
                                {t("base.button_edit", "Edit")}
                            </Button>
                            
                        </div>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end">
                    <Grid item xs>
                        <div>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        {organization?.physicalAddress?.verified && (
                                            <VerifiedIcon color="success" sx={{ mr: 1 }} />
                                        )}
                                        {organization?.physicalAddress?.address1
                                            ? organization?.physicalAddress?.address1
                                            : "123 Some Street"}
                                    </Typography>
                                    <Typography variant="h5">
                                        {organization?.physicalAddress?.city
                                            ? organization?.physicalAddress?.city + ", "
                                            : "City, "}
                                        {organization?.physicalAddress?.provinceCode
                                            ? organization?.physicalAddress?.provinceCode + " "
                                            : "ON "}
                                        {organization?.physicalAddress?.postal
                                            ? formatPostalCode(organization?.physicalAddress?.postal)
                                            : "A1B 2C3"}
                                    </Typography>
                                    {organization?.code && (
                                        <Typography color="inherit" sx={{ whiteSpace: "nowrap" }} variant="subtitle2">
                                            <StoreIcon fontSize="small" style={{ verticalAlign: "middle" }} />{" "}
                                            {organization?.code}
                                        </Typography>
                                    )}
                                    <Typography color="inherit" sx={{ whiteSpace: "nowrap" }} variant="subtitle2">
                                        <PhoneIcon fontSize="small" style={{ verticalAlign: "middle" }} />{" "}
                                        {organization?.phonePrimary
                                            ? formatPhone(organization?.phonePrimary)
                                            : "1234567890"}
                                    </Typography>

                                    {organization?.phoneSecondary && (
                                        <Typography variant="body1">Tel 2: {organization?.phoneSecondary}</Typography>
                                    )}
                                    {organization?.fax && (
                                        <Typography color="inherit" sx={{ whiteSpace: "nowrap" }} variant="subtitle2">
                                            <FaxIcon fontSize="small" style={{ verticalAlign: "middle" }} />{" "}
                                            {organization?.fax}
                                        </Typography>
                                    )}
                                    {organization?.email && (
                                        <Typography color="inherit" sx={{ whiteSpace: "nowrap" }} variant="subtitle2">
                                            <MailOutlineIcon fontSize="small" style={{ verticalAlign: "middle" }} />{" "}
                                            {organization?.email}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Typography variant="caption">
                                        {t("base.userView_site_parentOrganization", "Parent Organization")}:
                                    </Typography>
                                    <Box sx={{ mb: 1 }}>
                                        <Typography variant="h6" display={"inline"}>
                                            {organization?.organization?.name}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            display={"inline"}
                                            sx={{ fontSize: "0.8rem", fontWeight: 400 }}
                                        >
                                            {" "}
                                            {organization?.organization?.organizationType &&
                                                organization?.organization?.organizationType}
                                        </Typography>
                                    </Box>
                                    <Typography variant="caption">
                                        {t("base.userView_site_siteType", "Site Type")}:
                                    </Typography>
                                    <Typography variant="subtitle1">{organization?.siteType}</Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Box>
                                        <Typography variant="caption">
                                            {t("base.userView_site_servicesAvailable", "Services Available")}:
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexWrap: "wrap", // Ensures the chips wrap under the label
                                                gap: "8px", // Space between the chips
                                            }}
                                        >
                                            {organization?.services?.map((s) => (
                                                <Link key={s.name}>
                                                    <Chip
                                                        sx={{
                                                            borderRadius: "2px",
                                                        }}
                                                        label={s.name}
                                                        color="primary"
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </Link>
                                            ))}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {/* <Grid item align="center">
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            // className={classes.button}
                            sx={{ margin: 1, width: "120px" }}
                            startIcon={<EditIcon />}
                            // onClick={() => setEditSiteOpen(true)}
                            component={Link}
                            to="/portal/my-site/edit"
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            sx={{ margin: 1, width: "120px" }}
                            startIcon={<AssessmentIcon />}
                        >
                            Report
                        </Button>
                    </div>
                </Grid> */}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default OrganizationInfoCard;
