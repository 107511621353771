import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    IconButton,
    Link,
    Stack,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PencilSimple as PencilSimpleIcon } from "@phosphor-icons/react/dist/ssr/PencilSimple";
import ReactApexChart from "react-apexcharts";
import { DataTable } from "../../../components/DataTable/DataTable";
import { dayjs } from "../../../Helpers/dayjs";
import { Plus as PlusIcon } from "@phosphor-icons/react/dist/ssr/Plus";
import { Link as RouterLink } from "react-router-dom";
import { DataTableFilters } from "../../../components/DataTable/DataTableFilters";
import { DataTablePagination } from "../../../components/DataTable/DataTablePagination";

const columns = [
    {
        formatter(row) {
            const date = dayjs(row.lastSessionUtc).format("MMM D, YYYY h:mm A");
            return date !== "Invalid Date" ? date : "N/A";
        },
        name: "Date",
        width: "180px",
    },
    { field: "type", name: "Type", width: "120px" },
    {
        formatter: (row) => (
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                {/* <Avatar src={row.avatar}>{getInitials(row.name)}</Avatar>{" "} */}
                <div>
                    <Link
                        color="inherit"
                        component={RouterLink}
                        // to={`/admin/organizations/${orgId}/users/1`}
                        sx={{ whiteSpace: "nowrap" }}
                        variant="subtitle2"
                    >
                        {row.name}
                    </Link>
                    <Typography color="text.secondary" variant="body2">
                        {row.email}
                    </Typography>
                </div>
            </Stack>
        ),
        name: "Name",
        width: "210px",
    },
    { field: "phoneNumber", name: "Phone number", width: "150px" },
    {
        field: "vehicle",
        name: "Vehicle",
        width: "200px",
    },
    {
        field: "source",
        name: "Source",
        width: "100px",
    },
    {
        field: "quoter",
        name: "Quoter",
        width: "150px",
    },
    {
        formatter: (row) => {
            return (
                <IconButton
                    component={RouterLink}
                    to={row.path}
                >
                    <PencilSimpleIcon />
                </IconButton>
            );
        },
        name: "View",
        hideName: true,
        width: "50px",
        align: "center",
    },
];

const filteringOptions = [
    {
        field: "type",
        label: "Type",
        labelDescription: "Filter by Type",
        fieldType: "dropdown",
        values: ["Tires", "Packages", "Accessories"],
    },
    {
        field: "source",
        label: "Source",
        labelDescription: "Filter by Quoter",
        fieldType: "dropdown",
        values: ["Internal", "Retail"],
    },
    {
        field: "quoter",
        label: "Quoter",
        labelDescription: "Filter by Quoter",
        fieldType: "text",
        values: [],
    },
];

const sortingOptions = [
    {
        field: "date",
        label: "Date",
        order: ["asc", "desc"],
    },
    {
        field: "type",
        label: "Type",
        order: ["asc", "desc"],
    },
];

const orders = [
    {
        id: 123,
        name: "Fran Perez",
        type: "Package",
        email: "fran.perez@domain.com",
        phoneNumber: "(815) 704-0045",
        vehicle: "2024 Nissan Sentra SV",
        source: "Internal",
        quoter: "Fran Perez",
        path: "/portal/tires/orders/123",
        date: dayjs().subtract(1, "hour").toDate(),
    },
];

export default function Orders() {
    const chartState = {
        series: [
            {
                name: "This month's orders",
                data: [9, 6, 14, 13, 7, 18, 10, 15, 4, 9, 6, 6, 17, 12, 16, 9, 8, 11, 3],
            },
        ],
        options: {
            markers: {
                size: 5,
            },
            chart: {
                type: "line",
                toolbar: {
                    tools: {
                        download: true,
                        selection: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: false,
                    },
                },
            },
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: "smooth",
            },
            title: {
                text: "This Month's Orders",
                align: "left",
            },
            xaxis: {
                type: "category",
                categories: [
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                ],
            },
            yaxis: {
                labels: {
                    formatter: (val) => `${val.toLocaleString()}`,
                },
            },
            tooltip: {
                x: {
                    format: "MMMM", // Display full month name in tooltip
                },
            },
        },
    };

    return (
        <div>
            {/* <h1>Orders</h1> */}
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                    Orders
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                        <Grid item md={3}>
                            <Card>
                                <CardContent
                                    sx={{
                                        display: "grid",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <Typography variant="subtitle1">Orders Today</Typography>
                                    <Typography variant="h1">6</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={9}>
                            <div id="chart">
                                <ReactApexChart
                                    options={chartState.options}
                                    series={chartState.series}
                                    type="line"
                                    height={200}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Card sx={{ mt: 2 }}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ alignItems: "flex-start" }}>
                    <Box sx={{ flex: "1 1 auto" }}>
                        <CardHeader title={<Typography variant="h6">Orders List</Typography>} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                        <Button startIcon={<PlusIcon />} variant="contained" color="primary">
                            Create Order
                        </Button>
                    </Box>
                </Stack>
                <DataTableFilters
                    sorts={sortingOptions}
                    filters={filteringOptions}
                    initialSortDir={sortingOptions[0].field}
                />
                <DataTable columns={columns} rows={orders} />
                <DataTablePagination count={1} pageSize={25} pageNumber={1} />
            </Card>
        </div>
    );
}
