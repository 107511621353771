import React from "react";

export default function TireWheelPackages() {
    return (
        <div>
            <h1>Tire-Wheel Packages Page</h1>
            <p>
                This page will contain all the packages that are visible to the site manager. This data will be
                different from the package list on the User view since here the packages will contain additional
                information not visible to standard users and allow for actions such as price modifications and reports
                on a package-by-package basis.
            </p>
        </div>
    );
}
