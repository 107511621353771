import React from "react";
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Typography,
} from "@mui/material";
import { dayjs } from "../../../Helpers/dayjs";
import ReactApexChart from "react-apexcharts";
import ShopStatsSummary from "./ShopStatsSummary";
import { Storefront as StorefrontIcon } from '@phosphor-icons/react/dist/ssr/Storefront';

export default function SimpleShop({ messages }) {
    const chartState = {
        series: [
            {
                name: "Sales",
                data: [39081, 54930, 130294, 110022, 92023, 142930],
            },
        ],
        options: {
            chart: {
                // height: 200,
                type: "area",
                toolbar: {
                    tools: {
                        download: true,
                        selection: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: false,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            xaxis: {
                type: "category",
                categories: ["May", "June", "July", "August", "September", "October"],
            },
            yaxis: {
                labels: {
                    formatter: (val) => `$${val.toLocaleString()}`,
                },
            },
            tooltip: {
                x: {
                    format: "MMMM", // Display full month name in tooltip
                },
            },
        },
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar>
                        <StorefrontIcon fontSize="large" />
                    </Avatar>
                }
                title={<Typography variant="h6">My Shop</Typography>}
            />
            {/* <Box sx={{ px: 2, pt: 2 }}>
                <Typography variant="h5">My Shop</Typography>
            </Box> */}
            <CardContent sx={{ pb: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <ShopStatsSummary />
                        <Typography variant="h6">Shop Revenue</Typography>
                        <div id="chart">
                            <ReactApexChart
                                options={chartState.options}
                                series={chartState.series}
                                type="area"
                                height={200}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6">Top Shop Members</Typography>
                        <List
                            disablePadding
                            sx={{
                                p: 1,
                                "& .MuiListItemButton-root": { borderRadius: 1 },
                                "& .MuiBadge-dot": {
                                    border: "2px solid var(--mui-palette-background-paper)",
                                    borderRadius: "50%",
                                    bottom: "5px",
                                    height: "12px",
                                    right: "5px",
                                    width: "12px",
                                },
                            }}
                            subheader={
                                <ListSubheader
                                    disableGutters
                                    sx={{
                                        fontWeight: "bold",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        // px: 2,
                                    }}
                                >
                                    <span>Name</span>
                                    <span>Last Sale</span>
                                </ListSubheader>
                            }
                        >
                            {messages.map((message, index) => (
                                <React.Fragment key={index}>
                                    <ListItem disablePadding>
                                        <ListItemButton sx={{ p: 0 }}>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <Typography noWrap variant="subtitle2">
                                                        {message.author.name}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography color="text.secondary" noWrap variant="body2">
                                                        {message.content}
                                                    </Typography>
                                                }
                                            />
                                            <Typography
                                                color="text.secondary"
                                                sx={{ whiteSpace: "nowrap" }}
                                                variant="caption"
                                            >
                                                {dayjs(message.createdAt).fromNow()}
                                            </Typography>
                                        </ListItemButton>
                                    </ListItem>
                                    {index < messages.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
