import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from 'react-i18next';

// function noop() {
//   return undefined;
// }

export function DataTablePagination({ count = 0, pageSize = 25, pageNumber = 1 }) {
//  console.log(count, pageSize, pageNumber);
const { t } = useTranslation();
 
const [page, setPage] = React.useState(pageNumber - 1);
const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);

const handleChangePage = (event, page) => {
  setPage(page);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(event.target.value);
};

  // You should implement the pagination using a similar logic as the filters.
  // Note that when page change, you should keep the filter search params.

  return (
    <TablePagination
      component="div"
      count={count}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[25, 50, 100]}
      labelRowsPerPage={t("base.list_rowsPerPage", "Rows per page")}
    />
  );
}