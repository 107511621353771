import React, { useState } from "react";
import {
    Box,
    Button,
    Chip,
    Container,
    FormControl,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Paper,
    Rating,
    Select,
    TextField,
    Typography,
} from "@mui/material";

export default function ProductDetailPage() {
    // const [price, setPrice] = useState(200)
    const [quantity, setQuantity] = useState(1);

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    // useEffect(() => {
      
    
    //   setPrice()
    // }, [quantity])
    
    return (
        <Box>
            {/* Header */}
            <Box mb={4} mt={2}>
                <Typography variant="h4" component="h1">
                    Tire Brand - Model Name
                </Typography>
            </Box>

            {/* Product Overview Section */}
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <img src="/images/mock/tire2.jpg" alt="Tire Model" style={{ width: "250px" }} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant="h5" component="h2">
                        P215/65R15
                    </Typography>
                    <Typography variant="body1">Rim Diameter: 15 inches</Typography>
                    <Typography variant="body1">Load Index: 92</Typography>
                    <Typography variant="body1">Speed Rating: H (up to 130 mph)</Typography>
                    <Chip label="OEM" color="primary" sx={{ mt: 1, mr: 1 }} />
                    <Chip label="In Stock" color="primary" variant="outlined" sx={{ mt: 1 }} />
                    <Grid container spacing={2} sx={{ mt: 1, mb: 3 }} alignItems={"flex-end"}>
                        <Grid item>
                            <Typography variant="h4" color="primary" fontWeight="bold" sx={{ display: "inline" }}>
                                ${Math.floor(200)}
                            </Typography>
                            <Typography
                                variant="h5"
                                color="primary"
                                fontWeight="bold"
                                sx={{
                                    // fontSize: "0.75em",
                                    display: "inline",
                                }}
                            >
                                .{(200 % 1).toFixed(2).substring(2)}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ lineHeight: 0.5 }}>
                                PRICE
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" sx={{ display: "inline", color: "#182434" }}>
                                ${Math.floor(210)}
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "0.75em", display: "inline", color: "#182434" }}>
                                .{(210 % 1).toFixed(2).substring(2)}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ lineHeight: 0.5 }}>
                                RETAIL
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" sx={{ display: "inline", color: "#182434" }}>
                                ${Math.floor(230)}
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: "0.75em", display: "inline", color: "#182434" }}>
                                .{(230 % 1).toFixed(2).substring(2)}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ lineHeight: 0.5 }}>
                                INSTALL
                            </Typography>
                        </Grid>
                    </Grid>
                    <FormControl size="small" sx={{ mb: 1, width: "100px", mr: 2 }}>
                        <InputLabel id="quantity-label">Qty</InputLabel>
                        <Select
                            labelId="quantity-label"
                            value={quantity}
                            label="Qty"
                            onChange={handleQuantityChange}
                            fullWidth
                        >
                            {[1, 2, 3, 4, 5].map((qty) => (
                                <MenuItem key={qty} value={qty}>
                                    {qty}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ height: "40px", width: "100px" }} // Adjust height as needed to match FormControl
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>

            {/* Detailed Specifications Section */}
            <Box mt={4}>
                <Typography variant="h6" component="h3" gutterBottom>
                    Detailed Specifications
                </Typography>

                <Grid container spacing={2}>
                    {/* First column */}
                    <Grid item xs={12} md={4}>
                        <List>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Tread Depth" secondary="10/32 inches of tread depth." />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText
                                    primary="Tire Pressure (PSI)"
                                    secondary="Recommended tire pressure: 32 PSI."
                                />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText
                                    primary="UTQG Rating"
                                    secondary="300 A B - Treadwear grade 300, Traction A, Temperature B."
                                />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Rim Diameter" secondary="15 inch" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Run Flat Capacity" secondary="50 miles at reduced speed" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Tire Compound" secondary="Soft compound" />
                            </ListItem>
                        </List>
                    </Grid>

                    {/* Second column */}
                    <Grid item xs={12} md={4}>
                        <List>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Aspect Ratio" secondary="65" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Ply Rating" secondary="C - Carries moderate load" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText
                                    primary="Tread Pattern"
                                    secondary="Symmetrical tread pattern for even wear."
                                />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Offset (+/- Values)" secondary="+8 offset" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Traction Rating" secondary="AA" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Temperature Rating" secondary="B" />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <List>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Rolling Resistance" secondary="Low" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Noise Level (dB Rating)" secondary="76 dB" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText
                                    primary="Tread Pattern"
                                    secondary={
                                        <img
                                            src="/images/mock/AllTerrainSymbol.png"
                                            alt="all terrain"
                                            style={{ width: "40px", height: "auto" }}
                                        />
                                    }
                                />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Tire Balance" secondary="Medium" />
                            </ListItem>
                            <ListItem sx={{ py: 0 }}>
                                <ListItemText primary="Tire Alignment " secondary="Camber   " />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>
            </Box>

            {/* Key Benefits Section */}
            <Box mt={4}>
                <Typography variant="h6" component="h3" gutterBottom>
                    Key Benefits
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="body1">Low Rolling Resistance</Typography>
                            <Typography variant="body2">Improves fuel efficiency.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="body1">Quiet Design</Typography>
                            <Typography variant="body2">Reduces road noise for a comfortable ride.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="body1">Run-Flat Capability</Typography>
                            <Typography variant="body2">Continue driving after a puncture.</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Customer Reviews Section */}
            <Box mt={4}>
                <Typography variant="h6" component="h3" gutterBottom>
                    Technician Reviews
                </Typography>
                <Box>
                    {/* Reviewer details */}
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="body2" color="textSecondary">
                            John Doe
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            October 8, 2024
                        </Typography>
                    </Box>

                    {/* Review content */}
                    <Rating value={4} readOnly />
                    <Typography variant="body1">"Great tires! They handled well in the rain."</Typography>
                </Box>
                {/* Add a new review */}
                <Box mt={2}>
                    <Typography variant="h6" component="h3" gutterBottom>
                        Write a Review
                    </Typography>
                    <Rating
                        // value={rating} onChange={handleRatingChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Your Review"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        // value={review}
                        // onChange={handleReviewChange}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        // onClick={handleSubmitReview}
                        // disabled={!review || rating === 0}
                    >
                        Submit Review
                    </Button>
                </Box>
            </Box>

            {/* Related Products Section */}
            <Box mt={4}>
                <Typography variant="h6" component="h3" gutterBottom>
                    Related Products
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Paper sx={{ p: 2 }}>
                            <img src="/images/mock/tire1.jpg" alt="Related Product" style={{ height: "250px" }} />
                            <Typography variant="body1">Tire Model X</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper sx={{ p: 2 }}>
                            <img src="/images/mock/tire3.jpg" alt="Related Product" style={{ height: "250px" }} />
                            <Typography variant="body1">Tire Model Y</Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
