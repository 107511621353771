import React, { useState } from "react";
import {
    Button,
    Divider,
    FormControl,
    InputBase,
    InputLabel,
    MenuItem,
    Select,
    // TextField,
    Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { useEffect } from "react";
import settings from "../../../settings/api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getDomain, getSubdomain } from "../../../Helpers/getSubdomain";

const TextFieldContainer = styled("div")(({ theme }) => ({
    margin: theme.spacing(3, 0),
    width: "60%",
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        // backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 16,
        // width: "auto",
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

const BootstrapSelect = styled(InputBase)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            // borderRadius: 4,
            borderColor: theme.palette.primary.main,
            // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
    },
}));
const NewGroup = () => {
    const domain = getDomain();
    const subdomain = getSubdomain();
    const navigate = useNavigate();
    let { state } = useLocation();
    const [newGroup, setNewGroup] = useState({
        name: "",
        code: "",
        parentId: state?.parentId ? state.parentId : null,
        description: "",
        groupTypeId: "",
    });
    const [groupTypes, setGroupTypes] = useState([]);
    useEffect(() => {
        fetch(
            settings.api().organizations +
                `/${JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId}/group-types`,
            {
                method: "GET",
                headers: {
                    "X-Domain": domain,
                    "X-Tenant": subdomain,
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                },
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((data) => setGroupTypes(data));

        return () => {};
    }, []);

    const handleNewGroupInfoChange = (e) => {
        setNewGroup({ ...newGroup, [e.target.name]: e.target.value });
    };
    // console.log(newGroup);

    const createNewGroup = () => {
        // console.log(JSON.stringify(newGroup));
        fetch(
            settings.api().organizations +
                `/${JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId}/groups`,
            {
                method: "POST",
                headers: {
                    "X-Domain": domain,
                    "X-Tenant": subdomain,
                    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newGroup),
            }
        )
            .then((res) => {
                console.log(res);
                if (res.status === 201) {
                    navigate(
                        `/admin/organizations/${
                            JSON.parse(localStorage.getItem("userOrganizationInfo"))?.organizationId
                        }/groups`
                    );
                }
                // return res.json();
            })
            .then((data) => console.log(data));
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Create New Group
            </Typography>
            <Typography variant="subtitle1">New Group Information</Typography>
            <Divider />
            <div>
                <TextFieldContainer>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Name
                        </InputLabel>
                        <BootstrapInput
                            name={"name"}
                            value={newGroup.name}
                            onChange={(e) => handleNewGroupInfoChange(e)}
                        />
                    </FormControl>
                </TextFieldContainer>
                <TextFieldContainer>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Code
                        </InputLabel>
                        <BootstrapInput
                            name={"code"}
                            onChange={(e) => handleNewGroupInfoChange(e)}
                            value={newGroup.code}
                        />
                    </FormControl>
                </TextFieldContainer>
                <TextFieldContainer>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Parent Group
                        </InputLabel>
                        <BootstrapInput disabled name={"code"} value={newGroup.parentId} />
                    </FormControl>
                </TextFieldContainer>
                <TextFieldContainer>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Description
                        </InputLabel>
                        <BootstrapInput
                            multiline={true}
                            minRows={3}
                            name={"description"}
                            value={newGroup.description}
                            onChange={(e) => handleNewGroupInfoChange(e)}
                        />
                    </FormControl>
                </TextFieldContainer>
                <TextFieldContainer>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel shrink id="demo-simple-select-label" htmlFor="bootstrap-input">
                            Group Type
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={newGroup.groupTypeId}
                            name="groupTypeId"
                            onChange={(e) => handleNewGroupInfoChange(e)}
                            input={<BootstrapSelect />}
                        >
                            {groupTypes.length > 0 ? (
                                groupTypes.map((t) => (
                                    <MenuItem value={t.id} key={t.id}>
                                        {t.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>This is no group type. Please create a group type.</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </TextFieldContainer>
            </div>
            <Divider />
            <div style={{ marginTop: "10px" }}>
                <Button variant="contained" style={{ marginRight: "10px" }} onClick={createNewGroup}>
                    Create
                </Button>
                <Button
                    variant="outlined"
                    component={Link}
                    to={`/admin/organizations/${
                        JSON.parse(localStorage.getItem("userOrganizationInfo")).organizationId
                    }/groups`}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default NewGroup;
